import { IFileEntity } from 'types/entities/files.entity';
import {
  ICreateInvoiceByShipmentIdRequestBody,
  IGetAllInvoicesResponse,
  ICreateInvoiceFromDocRequestBody,
  IInvoiceEntity,
  IUpdateInvoiceRequestBody,
  IUpdateInvoicesRequestBody,
  IInvoiceVendor,
  IUpdateMultipleInvoiceStatusesRequestBody,
  IInvoiceHistory,
  IBulkUpdateInvoicesRequestBody,
} from 'types/entities/invoice.entity';
import { IInvoiceFilters } from 'types/feature/invoice-list';
import { IGetAllInvoicesPayload } from 'types/feature/pagination.types';

import { api } from './base-api';
import { ApiResponse } from './common/response.type';

export const fileAccessApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllInvoices: build.query<
      ApiResponse<IGetAllInvoicesResponse>,
      IGetAllInvoicesPayload
    >({
      query: (params) => ({
        url: `/invoice/list`,
        method: 'GET',
        params: { ...params },
      }),

      providesTags: ['InvoiceList'],
    }),
    updateAllInvoices: build.mutation<
      IGetAllInvoicesResponse,
      IUpdateInvoicesRequestBody
    >({
      query: ({ params, body }) => ({
        url: `/invoice/invoice-list/`,
        method: 'POST',
        params,
        body,
      }),
    }),
    bulkUpdateAllInvoices: build.mutation<
      IGetAllInvoicesResponse,
      IBulkUpdateInvoicesRequestBody
    >({
      query: (body) => ({
        url: `/invoice/status-vendor`,
        method: 'PUT',
        body,
      }),
    }),

    getInvoiceById: build.query<ApiResponse<IInvoiceEntity>, string>({
      query: (invoiceId) => `/invoice/${invoiceId}`,
      providesTags: (result, error, arg) => [{ type: 'InvoiceById', id: arg }],
    }),

    updateInvoiceById: build.mutation<
      IInvoiceEntity,
      IUpdateInvoiceRequestBody
    >({
      query: (payload) => ({
        url: `/invoice/${payload.id}`,
        method: 'PUT',
        body: payload.invoice,
      }),
      invalidatesTags: [
        'InvoiceList',
        'InvoiceById',
        'InvoiceListByShipment',
        'InvoiceCount',
        'InvoicePendingReview',
        'invoiceOnHold',
        'InvoicePendingApproval',
        'InvoiceDashboard',
        'InvoiceHistoryById',
      ],
    }),

    updateMultipleInvoiceStatuses: build.mutation<
      IInvoiceEntity,
      IUpdateMultipleInvoiceStatusesRequestBody
    >({
      query: (payload) => ({
        url: `/invoice/status`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [
        'InvoiceList',
        'InvoiceById',
        'InvoiceListByShipment',
        'InvoiceCount',
        'InvoicePendingReview',
        'invoiceOnHold',
        'InvoicePendingApproval',
        'InvoiceDashboard',
        'InvoiceHistoryById',
      ],
    }),

    updateInvoiceAssignee: build.mutation<
      IInvoiceEntity,
      IUpdateInvoiceRequestBody
    >({
      query: (payload) => ({
        url: `/invoice/assignee/${payload.id}`,
        method: 'PUT',
        body: payload.invoice,
      }),
      invalidatesTags: ['InvoiceList', 'InvoiceById', 'InvoiceListByShipment'],
    }),
    deleteInvoiceById: build.mutation<IInvoiceEntity, string>({
      query: (invoiceId) => ({
        url: `/invoice/${invoiceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'InvoiceById',
        'InvoiceListByShipment',
        'InvoiceList',
        'InvoiceCount',
        'InvoicePendingReview',
        'invoiceOnHold',
        'InvoicePendingApproval',
        'InvoiceDashboard',
      ],
    }),
    downloadInvoiceSheet: build.mutation<
      { data: Iterable<number> },
      { invoiceIds: string[]; filters?: IInvoiceFilters; keyword: string }
    >({
      query: ({ invoiceIds, filters, keyword }) => ({
        url: `/invoice/download?keyword=${keyword}`,
        method: 'POST',
        body: { invoiceIds, filters },
        responseType: 'arraybuffer',
        contentType: 'blob',
      }),
    }),
    getAllInvoiceByShipmentId: build.query<
      ApiResponse<{ invoices: IInvoiceEntity[] }>,
      string
    >({
      query: (shipmentId) => `/invoice/shipment/${shipmentId}`,
      providesTags: ['InvoiceListByShipment'],
    }),
    getInvoiceVendors: build.query<ApiResponse<IInvoiceVendor[]>, void>({
      query: (shipmentId) => `invoice/vendors`,
    }),

    createInvoiceByShipmentId: build.mutation<
      ApiResponse<IInvoiceEntity>,
      ICreateInvoiceByShipmentIdRequestBody
    >({
      query: (body) => ({
        url: `/invoice/parse/${body.shipmentId}`,
        method: 'POST',
        body: body.file,
        formData: true,
      }),
      invalidatesTags: ['InvoiceListByShipment'],
    }),
    getFilesByInvoiceId: build.query<ApiResponse<IFileEntity[]>, string>({
      query: (invoiceId) => `/invoice/files/${invoiceId}`,
    }),
    getHistoryByInvoiceId: build.query<ApiResponse<IInvoiceHistory>, string>({
      query: (invoiceId) => `/invoice/history/${invoiceId}`,
      providesTags: ['InvoiceHistoryById'],
    }),

    createInvoiceFromUrl: build.mutation<
      ApiResponse<IInvoiceEntity>,
      ICreateInvoiceFromDocRequestBody
    >({
      query: (payload) => {
        const { shipmentId, ...body } = payload;
        return {
          url: `/invoice/parse/document/${shipmentId}`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['InvoiceListByShipment'],
    }),
  }),
});

export const {
  useGetAllInvoicesQuery,
  useLazyGetAllInvoicesQuery,
  useGetInvoiceByIdQuery,
  useLazyGetInvoiceByIdQuery,
  useUpdateInvoiceByIdMutation,
  useUpdateMultipleInvoiceStatusesMutation,
  useDeleteInvoiceByIdMutation,
  useGetAllInvoiceByShipmentIdQuery,
  useCreateInvoiceByShipmentIdMutation,
  useCreateInvoiceFromUrlMutation,
  useUpdateInvoiceAssigneeMutation,
  useUpdateAllInvoicesMutation,
  useGetInvoiceVendorsQuery,
  useLazyGetFilesByInvoiceIdQuery,
  useGetHistoryByInvoiceIdQuery,
  useDownloadInvoiceSheetMutation,
  useBulkUpdateAllInvoicesMutation,
} = fileAccessApi;
