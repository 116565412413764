import { IInvoiceEntity } from 'types/entities/invoice.entity';
import { InvoiceStatusEnum } from 'types/feature/invoice.types';

import { splitState } from 'utils/format-fields';

// TODO: function no longer needed (deprecate)
export const computeInvoiceStatusLabel = (status: InvoiceStatusEnum) =>
  splitState(status as unknown as string);

export const mappedInvoiceStatuses = Object.values(InvoiceStatusEnum).map(
  (status: InvoiceStatusEnum) => ({
    label: computeInvoiceStatusLabel(status),
    value: status,
    key: status,
  })
);
export const getAllowedInvoiceStatuses = (invoice: IInvoiceEntity) =>
  Object.values(invoice.allowedStatusUpdates).map(
    (status: InvoiceStatusEnum) => ({
      label: computeInvoiceStatusLabel(status),
      value: status,
      key: status,
    })
  );

export const isInvoiceApproved = (status: InvoiceStatusEnum) =>
  status === InvoiceStatusEnum.ACCEPTED || status === InvoiceStatusEnum.PAID;

export const computeTagColor = (status: InvoiceStatusEnum) => {
  switch (status) {
    case InvoiceStatusEnum.DRAFT:
      return 'default';

    case InvoiceStatusEnum.IN_REVIEW:
      return 'orange';

    case InvoiceStatusEnum.ACCEPTED:
      return 'green';

    case InvoiceStatusEnum.DECLINED:
      return 'red';

    case InvoiceStatusEnum.PAID:
      return 'blue';

    default:
      return 'default';
  }
};
