import { IBookingEntity } from 'types/entities/booking.entity';
import { ICollaboratorsEntity } from 'types/entities/collaborator.entity';
import { IPurchaseOrderCreateBody } from 'types/entities/purchase-order.entity';
import {
  IShipmentEntity,
  IShipmentTypeEntity,
} from 'types/entities/shipment.entity';

import { AccessLevelEnum } from './purchase-orders.types';

export interface IBookingCollaboratorsProps {
  bookingId: string;
  showCollaboratorModal?: (id: string, accessLevel: AccessLevelEnum) => void;
  hideCollaboratorModal?: () => void;
  currentUserAccessLevel: AccessLevelEnum;
  isCollaboratorOpen: boolean;
}

export interface IBookingCollaborators {
  id: string;
  fullname: string;

  email: string;
  photo: string | null;
  isCollaborator: boolean;
  companyId: string;
  companyName: string;
  accessLevel: AccessLevelEnum;
  addedBy: string;
}

export interface CreateBookingsReqBody {
  purchaseOrders: {
    id: string;
    purchaseOrderItems: {
      id: string;
      quantity: number;
    }[];
  }[];
}

export interface CreateBookingsForReqBody {
  body: IPurchaseOrderCreateBody;
  shipmentId: string;
}

export interface UpdateBookingsReqBody extends CreateBookingsReqBody {
  bookingIds: string[];
}

export interface CreateBookingsResBody {
  bookingIds: string[];
}

export interface UnassignBookingsReqBody {
  bookingId: string;
  shipmentId: string;
}
export interface AssignBookingsReqBody {
  bookingIds: string[];
  shipmentId: string;
  isCreateMode: boolean;
}

export interface IBookingsProps {
  shipment?: IShipmentEntity;
}

export enum BookingTabEnum {
  ALL = 'ALL',
  IN_PROGRESS = 'IN_PROGRESS',
  UNASSIGNED = 'UNASSIGNED',
}

export enum EditMode {
  ASSIGN = 'Assign',
  EDIT = 'Edit',
}

export interface IBookingsTableProps {
  shipmentId?: string;
  shipmentSlug?: string;
  currentCollaborator?: ICollaboratorsEntity;
}
export interface BookingCreateModalProps extends IBookingsTableProps {
  isShipmentTab: boolean;
}
export interface IBookingAssignButtonProps {
  booking: IBookingEntity;
  isDisabled: boolean;
  openAssignDrawer: () => void;
}

export interface IBookingsDrawerState {
  open: false | EditMode;
  bookingId: string | null;
}

export interface IBookingsDrawerProps {
  bookingId: string | null;
  isOpen: boolean;
  onCloseEditModal: () => void;
  onUnassign: (id: string, shipmentId?: string) => void;
  onAssign: () => void;
}

export interface IAssignBookingsShipmentProps {
  existingShipment: boolean;
  ids?: string[];
  shipmentTypes: IShipmentTypeEntity | undefined;
}

export interface IBookingCommentsProps {
  bookingId: string;
}

export interface IBookinContainerDropDown {
  bookingId: string;
}
