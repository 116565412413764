const danubeCompany: string[] = [
  '86a99d5d-da7b-454d-9b73-af2306a8816f',
  // TODO: For stage test rehan+aco user id
  'ce03ffda-02eb-4b0f-88e9-350166a127f0',
];

export const isDanube = (id: string) => !!danubeCompany.includes(id);

const danubeUsersAccountTeam: string[] = [
  '7c42b8e1-f66e-41c0-a81a-ab914571b859',
  '9d80a999-eb15-4f62-96f6-2a6af8efa460',

  // Internal Test user on prod
  '38f61f8f-289e-4a7e-91fb-3b01175a2937',

  // TODO: For stage test rehan+aco user id
  '93440aaf-bbc2-4a85-b561-99f1b0767f51',
];

export const isDanubeAccountTeam = (userId: string) =>
  !!danubeUsersAccountTeam.includes(userId);
