import { Button, DatePicker, Form, Modal, Select, notification } from 'antd';
import {
  useBulkUpdateAllInvoicesMutation,
  useGetInvoiceVendorsQuery,
} from 'api/invoice';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  IBulkUpdateInvoiceFormData,
  IInvoiceVendor,
} from 'types/entities/invoice.entity';
import {
  IBulkUpdateInvoiceModalProps,
  InvoiceStatusEnum,
} from 'types/feature/invoice.types';

import { computeInvoiceStatusLabel } from '../InvoiceStatusSwitch/helpers/helper';

const { confirm } = Modal;
const { RangePicker } = DatePicker;

const BulkUpdateInvoiceModal: React.FC<IBulkUpdateInvoiceModalProps> = ({
  onBulkStatusUpdate,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm<IBulkUpdateInvoiceFormData>();

  const { data: vendorsData, isLoading: isVendorDataLoading } =
    useGetInvoiceVendorsQuery();

  const [
    updateStatus,
    {
      isLoading: isStatusLoading,
      isSuccess: isStatusSuccess,
      isError: isStatusError,
      error: statusErrorData,
    },
  ] = useBulkUpdateAllInvoicesMutation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errorNotification = (error: any) => {
    notification.error({
      message: 'Error',
      description:
        error?.data?.message ?? 'Sorry, Unable To Update Invoices Status',
    });
  };

  const resetData = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isStatusSuccess) {
      resetData();
      onBulkStatusUpdate();
      notification.success({
        message: 'Invoices Status Updated',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStatusSuccess]);

  useEffect(() => {
    if (statusErrorData && isStatusError) {
      errorNotification(statusErrorData);
    }
  }, [isStatusError, statusErrorData]);

  const onFormSubmit = async (values: IBulkUpdateInvoiceFormData) => {
    try {
      await form.validateFields();
      await updateStatus({
        vendorId: values.vendor,
        statusFrom: values.statusFrom,
        statusTo: values.statusTo,
        startDate: dayjs(values.date?.[0]).format('YYYY-MM-DD'),
        endDate: dayjs(values.date?.[1]).format('YYYY-MM-DD'),
      });
    } catch (error: unknown) {
      notification.error({
        message: 'Validation Error',
        description: 'Make sure to fill all required fields !',
      });
    }
  };

  const onFinish = (values) => {
    confirm({
      title: 'Confirmation',
      okText: 'Accept',
      okType: 'primary',
      icon: null,
      content: (
        <div>
          <p>Are you sure you want to update status for the selected vendor?</p>
          <p>
            <strong>Note:</strong> On submitting this, all invoices that match
            with the vendor and the dates selected will be updated.
          </p>
        </div>
      ),
      onOk: async () => {
        onFormSubmit(values);
        return;
      },
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    resetData();
  };
  const renderVendorOptions = (options: IInvoiceVendor[]) => {
    return Object.values(options).map((vendor) => {
      return { label: vendor.name, value: vendor.id };
    });
  };
  const statusOptions = Object.values(InvoiceStatusEnum).map((status) => {
    return { label: computeInvoiceStatusLabel(status), value: status };
  });

  return (
    <div>
      <Button onClick={showModal}>Bulk Update Status</Button>
      <Modal
        title="Bulk Update Invoice Status"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            form="status-form"
            loading={isStatusLoading}
          >
            Submit
          </Button>,
        ]}
      >
        <div className="status-form-wrapper">
          <Form
            id="status-form"
            className="status-form"
            layout="vertical"
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              label="Vendor"
              name="vendor"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              {vendorsData && vendorsData.data && (
                <Select
                  loading={isVendorDataLoading}
                  options={renderVendorOptions(vendorsData.data)}
                />
              )}
            </Form.Item>
            <Form.Item
              label="Invoice Date"
              name="date"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <RangePicker />
            </Form.Item>
            <Form.Item
              label="Status From"
              name="statusFrom"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              {vendorsData && (
                <Select
                  showSearch
                  placeholder="Select Invoices With Status ..."
                  options={statusOptions}
                />
              )}
            </Form.Item>
            <Form.Item
              label="Status To"
              name="statusTo"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              {vendorsData && (
                <Select
                  showSearch
                  placeholder="Select Status To Change To ..."
                  options={statusOptions}
                />
              )}
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default BulkUpdateInvoiceModal;
