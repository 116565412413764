import {
  Button,
  Form,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
  notification,
} from 'antd';
import { useGetAllCarriersQuery } from 'api/carrier';
import { useCreateContainerMutation } from 'api/container';
import {
  useAddAirTrackingMutation,
  useAddTrackingByBillMutation,
} from 'api/tracking';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { ModalityEnum } from 'types/entities/shipment.entity';
import {
  ContainerModalTypeEnum,
  IAddContainerModalProps,
  IContainerFormData,
} from 'types/feature/container.types';
import { IGetTrackingParams } from 'types/feature/tracking.types';

import { activateLiveTrackingEvent } from 'utils/analytics-events';
import { filterCarriers, getCarriersOptions } from 'utils/carriers';
import {
  isAirTransportation,
  isRoadTransportation,
  isSeaTransportation,
} from 'utils/shipment-type';

import './add-container-modal.scss';

const { confirm } = Modal;

const getTrackingParams: IGetTrackingParams = (type) => {
  if (isAirTransportation(type)) {
    return { type, modality: ModalityEnum.AIR };
  } else if (isRoadTransportation(type)) {
    return { modality: ModalityEnum.ROAD };
  } else {
    return { modality: ModalityEnum.SEA };
  }
};

const AddContainerModal: React.FC<IAddContainerModalProps> = ({
  actionButton,
  shipmentType,
  defaultData,
  shipmentId,
  containerModalType,
  showConfirmModal,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm<IContainerFormData>();
  const [isContainer, setIsContainer] = useState<boolean>(
    containerModalType === ContainerModalTypeEnum.CONTAINER
  );
  const hideContainerType =
    containerModalType !== ContainerModalTypeEnum.BOTH ||
    !isSeaTransportation(shipmentType);

  const user = useSelector((state: RootState) => state.user.user);

  const { data: carrierData, isLoading: isCarrierLoading } =
    useGetAllCarriersQuery(getTrackingParams(shipmentType));

  const [
    addSeaTrackingByContainer,
    {
      isLoading: isSeaTrackingByContainerLoading,
      isSuccess: isSeaTrackingByContainerSuccess,
      isError: isSeaTrackingByContainerError,
      error: seaTrackingByContainerErrorData,
    },
  ] = useCreateContainerMutation();

  const [
    addSeaTrackingByMbl,
    {
      isLoading: isSeaTrackingByMblLoading,
      isSuccess: isSeaTrackingByMblSuccess,
      isError: isSeaTrackingByMblError,
      error: seaTrackingByMblErrorData,
    },
  ] = useAddTrackingByBillMutation();

  const [
    addAirTracking,
    {
      isLoading: isAirTrackingLoading,
      isSuccess: isAirTrackingSuccess,
      isError: isAirTrackingError,
      error: airTrackingErrorData,
    },
  ] = useAddAirTrackingMutation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errorNotification = (error: any) => {
    notification.error({
      message: 'Error',
      description: error?.data?.message ?? 'Sorry, Unable to update container',
    });
  };

  const resetData = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (
      isAirTrackingSuccess ||
      isSeaTrackingByMblSuccess ||
      isSeaTrackingByContainerSuccess
    ) {
      resetData();
      activateLiveTrackingEvent({
        user_id: user?.id,
        email: user?.email,
        company: user?.company?.name || '',
        shipment_id: shipmentId,
        company_id: user?.companyId,
      });

      notification.success({
        message: 'Tracking Number Added!',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSeaTrackingByContainerSuccess,
    isSeaTrackingByMblSuccess,
    isAirTrackingSuccess,
  ]);

  useEffect(() => {
    const errorData =
      airTrackingErrorData ||
      seaTrackingByMblErrorData ||
      seaTrackingByContainerErrorData;
    if (errorData) {
      errorNotification(errorData);
      //close the modal even if the tracking number is invalid
      if ('status' in errorData && errorData.status === 400) {
        resetData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAirTrackingError,
    isSeaTrackingByMblError,
    isSeaTrackingByContainerError,
    airTrackingErrorData,
    seaTrackingByMblErrorData,
    seaTrackingByContainerErrorData,
  ]);

  const onFormSubmit = async (values: IContainerFormData) => {
    try {
      await form.validateFields();
      if (isSeaTransportation(shipmentType)) {
        if (isContainer) {
          addSeaTrackingByContainer({
            shipmentId,
            container: {
              carrierId: values.carrierId,
              number: values.number,
            },
          });
        } else {
          addSeaTrackingByMbl({
            shipmentId,
            carrierId: values.carrierId,
            mbl: values.number,
          });
        }
      } else {
        addAirTracking({
          shipmentId,
          waybillIdentification: values.number,
          carrierId: values.carrierId,
        });
      }
    } catch (error: unknown) {
      notification.error({
        message: 'Validation Error',
        description: 'Make sure to fill all required fields !',
      });
    }
  };

  const onFinish = (values: IContainerFormData) => {
    if (showConfirmModal) {
      confirm({
        title: 'Confirmation',
        okText: 'Accept',
        okType: 'primary',
        icon: null,
        content: (
          <div>
            <p>Are you sure you want to updated tracking number? </p>
            <p>
              <strong>Note:</strong> On submitting this shipment existing
              containers and tracking details will be replaced with new one.
            </p>
          </div>
        ),
        onOk: async () => {
          onFormSubmit(values);
          return;
        },
      });
    } else {
      onFormSubmit(values);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    resetData();
  };

  const onTrackingTypeChange = (e: RadioChangeEvent) => {
    setIsContainer(!!(e.target.value === 'container'));
  };

  return (
    <div>
      <Button type="default" onClick={showModal} {...actionButton}>
        {actionButton?.text !== undefined
          ? actionButton?.text
          : 'Add Container'}
      </Button>
      <Modal
        title={isContainer ? 'Add Container' : 'Add Tracking Number'}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            form="container-form"
            loading={
              isAirTrackingLoading ||
              isSeaTrackingByContainerLoading ||
              isSeaTrackingByMblLoading
            }
          >
            Submit
          </Button>,
        ]}
      >
        <div className="container-form-wrapper">
          <Form
            id="container-form"
            className="container-form"
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
              containerType: isContainer ? 'container' : 'lading-bill',
              ...(defaultData ? defaultData : {}),
            }}
          >
            <Form.Item name="containerType" hidden={hideContainerType}>
              <Radio.Group onChange={onTrackingTypeChange}>
                <Radio value="container"> Container </Radio>
                <Radio value="lading-bill"> Bill of Lading </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={isContainer ? 'Container Number' : 'Tracking Number'}
              name="number"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input
                placeholder={
                  isContainer
                    ? 'Enter container number'
                    : 'Enter MBL, AWB, Tracking Number, or Booking Number'
                }
              />
            </Form.Item>

            <Form.Item
              label="Carrier"
              name="carrierId"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              {carrierData && (
                <Select
                  showSearch
                  placeholder="Select Carrier"
                  loading={isCarrierLoading}
                  filterOption={filterCarriers}
                  options={getCarriersOptions(carrierData)}
                />
              )}
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default AddContainerModal;
