import {
  Button,
  Checkbox,
  InputNumber,
  Slider,
  SliderSingleProps,
  Space,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { VarianceFilterProps } from 'types/feature/invoice-list';

import './invoice-variance-filter.scss';

const VarianceFilter: React.FC<VarianceFilterProps> = ({
  onFilterChange,
  defaultValue,
}) => {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(100000);
  const [isChecked, setIsChecked] = useState(false);

  const marks: SliderSingleProps['marks'] = {
    0: '0',
    5000: '5000',
    10000: '10000',
  };
  const onFinish = () => {
    onFilterChange([min, max], 'variance');
  };
  useEffect(() => {
    if (!defaultValue) {
      setMin(0);
      setMax(10000);
    }
  }, [defaultValue]);
  return (
    <div className="variance-filter-wrapper">
      <Space direction="vertical" size={20}>
        <Checkbox
          checked={isChecked}
          onChange={(e) => {
            setIsChecked(e.target.checked);
            if (e.target.checked) {
              onFilterChange([0, 100000], 'variance');
            }
          }}
        >
          Filter All invoices with variance
        </Checkbox>
        <div className="more-options">
          <Typography.Title level={5}>More options</Typography.Title>
          <div className="input-wrapper">
            <InputNumber
              disabled={isChecked}
              className="input-number"
              value={min}
              defaultValue={min}
              onChange={(value) => {
                if (value !== null) {
                  setMin(value);
                }
              }}
              placeholder="from"
            />
            <InputNumber
              disabled={isChecked}
              className="input-number"
              value={max}
              defaultValue={max}
              onChange={(value) => {
                if (value !== null) {
                  setMax(value);
                }
              }}
              placeholder="to"
            />
          </div>
          <Slider
            disabled={isChecked}
            marks={marks}
            min={0}
            max={10000}
            onChange={(value) => {
              setMin(value[0]);
              setMax(value[1]);
            }}
            range={true}
            defaultValue={[min, max]}
            value={[min, max]}
          />
          <Button disabled={isChecked} type="primary" onClick={onFinish}>
            Filter
          </Button>
        </div>
      </Space>
    </div>
  );
};

export default VarianceFilter;
