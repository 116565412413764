import { DeleteFilled, HistoryOutlined } from '@ant-design/icons';
import { Space, Button, Modal, Badge, notification, Tooltip } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import {
  useCreateSuggestedProposalByIdMutation,
  useDeleteProposalMutation,
  useReawardProposalMutation,
} from 'api/proposal';
import { formatShipmentAmount } from 'app/components/ShipmentCreation/ShipmentCreationForm/shipment.utils';
import { useProposalState } from 'providers/proposal-provider';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IProposalEntity } from 'types/entities/proposal.entity';
import { IQuoteStatus } from 'types/entities/quote.entity';
import {
  IProposalTableProps,
  TOnEditProposalClickProps,
} from 'types/feature/proposals.types';

import { isManager, isViewer } from 'utils/collaborators';
import { displayErrors } from 'utils/error-notification';
import { filterColumns } from 'utils/filter-table-columns';
import {
  isProposalAccepted,
  isProposalActionEnabled,
  isProposalEditDisabled,
} from 'utils/proposal-helper';
import { isForwarder, isAdminCO } from 'utils/shipment-helper';

import ProposalActions from '../ProposalActions';
import ProposalDetailsModal from '../ProposalDetailsModal';
import ProposalEditModal from '../ProposalEditModal';
import ProposalHistory from '../ProposalHistory';
import { proposalTableColumns, quickViewColumns } from './proposal-table.const';
import './proposal-table.scss';

const { confirm } = Modal;

export const computeReawardProposalMessage = (
  proposalData: IProposalEntity[],
  selectedProposal: IProposalEntity
) => {
  const revokedProposal = proposalData.find(
    (proposal) => proposal.status === IQuoteStatus.ACCEPTED
  );
  return (
    <>
      <p>
        You had previously accepted the quote from{' '}
        <strong>
          {revokedProposal?.company.name} (RFQ-{revokedProposal?.slug})
        </strong>
      </p>
      <p>
        Would you like to decline the mentioned quote and accept the new
        proposal from{' '}
        <strong>
          {selectedProposal?.company.name} (RFQ-{selectedProposal?.slug})
        </strong>
      </p>
    </>
  );
};

export const ProposalTable: FC<IProposalTableProps> = ({
  shipment,
  filteredProposals,
  selectedColumns,
}) => {
  const company = useSelector((state: RootState) => state.user.user?.company);
  const currentUser = useSelector((state: RootState) => state.user.user);
  const [showProposalEditModal, setShowProposalEditModal] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const renderExpandRow = (record: IProposalEntity) => {
    return <ProposalHistory proposalId={record.id} />;
  };
  const expandable = {
    showExpandColumn: false,
    expandedRowKeys: expandedRowKeys,
    expandedRowRender: renderExpandRow,
  };

  const handleExpandCollapse = async (recordId: string) => {
    setExpandedRowKeys((prevState) => {
      if (prevState.includes(recordId)) {
        return prevState.filter((id) => id !== recordId);
      } else {
        return [...prevState, recordId];
      }
    });
  };
  const [isFieldEditDisabled, setIsFieldEditDisabled] =
    useState<boolean>(false);

  const proposalContext = useProposalState();

  const {
    showProposalDetailsModal,
    setShowProposalDetailsModal,
    selectedProposal,
    setSelectedProposal,
  } = proposalContext;

  const canDelete = (proposalStatus: IQuoteStatus) =>
    shipment.createdBy.company.id === shipment.currentCollaborator.companyId &&
    proposalStatus === IQuoteStatus.REQUESTED;

  //hooks

  const [createSuggestedProposal] = useCreateSuggestedProposalByIdMutation();

  const [reawardProposal] = useReawardProposalMutation();

  const [deleteProposal, { isLoading: isDeleteLoading }] =
    useDeleteProposalMutation();

  // Updates selectedProposal on proposals state update
  useEffect(() => {
    if (selectedProposal) {
      const extractedProposal = filteredProposals.find(
        (proposal) => proposal.id === selectedProposal.id
      );
      if (extractedProposal) {
        setSelectedProposal(extractedProposal);
      }
    }
    // eslint-disable-next-line
  }, [filteredProposals]);

  const onCreateSuggestedProposal = async (proposal: IProposalEntity) => {
    try {
      await createSuggestedProposal({
        proposalId: proposal.id,
      }).unwrap();
      // eslint-disable-next-line
    } catch (error: any) {
      displayErrors(error, { title: 'Error on creating suggested proposal' });
    }
  };

  const onEditProposalClick: TOnEditProposalClickProps = ({
    proposal,
    isForwarderEdit,
  }) => {
    setSelectedProposal(proposal);
    setShowProposalEditModal(true);

    if (isForwarderEdit) {
      setIsFieldEditDisabled(false);
    } else {
      const isEditDisabled = isProposalEditDisabled(
        isAdminCO(currentUser?.userRole, shipment.currentUserRole),
        proposal.status,
        !!(currentUser && proposal?.createdBy === currentUser?.id)
      );
      setIsFieldEditDisabled(isEditDisabled);
    }
  };

  const onEditProposalConfirm = (proposal: IProposalEntity) => {
    confirm({
      title: 'Confirmation',
      okText: 'Edit Proposal',
      okType: 'default',
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'primary',
      },
      icon: null,
      content: proposal.isSuggestedProposalExist
        ? 'This proposal changes are already under review. Are you sure you want to edit this proposal?'
        : 'This proposal is accepted by Cargo owner. Are you sure you want to edit this proposal?',
      onOk: async () => {
        if (
          isProposalAccepted(proposal.status) &&
          !proposal.isSuggestedProposalExist
        ) {
          await onCreateSuggestedProposal(proposal);
        }

        onEditProposalClick({
          proposal,
          isForwarderEdit: true,
        });
      },
    });
  };

  const onReawardClick = (currentProposal: IProposalEntity) => {
    confirm({
      title: 'Are you sure?',
      okText: 'Re-award',
      icon: null,
      content: computeReawardProposalMessage(
        filteredProposals,
        currentProposal
      ),
      onOk: async () => {
        try {
          await reawardProposal({ id: currentProposal.id }).unwrap();

          notification.success({
            message: (
              <p>
                Proposal{' '}
                <strong>
                  {currentProposal.company.name} (RFQ-{currentProposal.slug})
                </strong>{' '}
                re-awarded successfuly
              </p>
            ),
          });
        } catch (error: any) {
          displayErrors(error, { title: 'Error on re-awarding proposal' });
        }
        return;
      },
    });
  };
  const onDeleteClick = (proposalId: string) => {
    confirm({
      title: 'Are you sure you want to delete this proposal?',
      okText: 'Delete',
      okType: 'danger',
      icon: null,
      onOk: async () => {
        try {
          await deleteProposal(proposalId).unwrap();

          notification.success({
            message: <p>Proposal was deleted successfuly</p>,
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          displayErrors(error, { title: 'Error on deleting proposal' });
        }
        return;
      },
    });
  };

  const onDetailProposalClick = (proposal: IProposalEntity) => {
    setSelectedProposal(proposal);
    setShowProposalDetailsModal(true);
  };

  const onEditProposalClose = () => {
    setSelectedProposal(null);
    setShowProposalEditModal(false);
  };

  const onDetailProposalClose = () => {
    setSelectedProposal(null);
    setShowProposalDetailsModal(false);
  };

  const onEditClick = (proposal: IProposalEntity) => {
    if (
      isProposalAccepted(proposal.status) &&
      isForwarder(shipment.currentUserRole)
    ) {
      onEditProposalConfirm(proposal);
    } else {
      onEditProposalClick({
        proposal,
        isForwarderEdit: false,
      });
    }
  };

  const onDetailProposalEditClick = (proposal: IProposalEntity) => {
    onDetailProposalClose();
    onEditClick(proposal);
  };

  const renderProposalTableColumns: ColumnsType<IProposalEntity> = [
    {
      title: 'Quotes ID',
      dataIndex: 'quotes-id',
      key: 'quotes-id',
      width: 100,
      render: (_, proposal) => (
        <Space size={[1, 1]}>
          {proposal.isSuggestedProposalExist && <Badge dot />}

          <Button type="link" onClick={() => onDetailProposalClick(proposal)}>
            {proposal.slug}
          </Button>
        </Space>
      ),
    },
    ...filterColumns(
      proposalTableColumns(
        shipment.createdBy.company.currency,
        formatShipmentAmount(
          shipment.shipmentType,
          shipment.cargoUnits,
          shipment.shipmentItems
        )
      ),
      selectedColumns,
      quickViewColumns
    ),
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      align: 'center',
      fixed: 'right',
      width: 250,
      onCell: () => {
        return {
          onClick: (event) => {
            event.stopPropagation(); // this will avoid onRow being called
          },
        };
      },

      render: (_, proposal) => {
        const isActionEnabled = isProposalActionEnabled(proposal, shipment, [
          IQuoteStatus.REQUESTED,
          IQuoteStatus.DECLINED,
        ]);
        return isViewer(shipment.currentCollaborator?.accessLevel) ? (
          <Button type="link" onClick={() => onDetailProposalClick(proposal)}>
            View
          </Button>
        ) : (
          <Space size={[1, 1]}>
            <Button type="link" onClick={() => onEditClick(proposal)}>
              Edit
            </Button>

            {canDelete(proposal.status) && (
              <Button
                icon={<DeleteFilled />}
                danger
                loading={isDeleteLoading}
                size="small"
                type="text"
                onClick={() => onDeleteClick(proposal.id)}
              />
            )}
            {isActionEnabled && (
              <ProposalActions
                shipment={shipment}
                proposal={proposal}
                actionButton={{ isBlock: false, size: 'small' }}
              />
            )}
            <Tooltip title="See Status History">
              <Button
                type="text"
                icon={<HistoryOutlined />}
                onClick={() => {
                  handleExpandCollapse(proposal.id);
                }}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const onRow = (record: IProposalEntity, index: number | undefined) => {
    return {
      onClick: () => {
        onDetailProposalClick(record);
      },
    };
  };

  return (
    <>
      <Table
        className="proposal-qoutes-table"
        rowKey="id"
        columns={renderProposalTableColumns}
        dataSource={filteredProposals}
        pagination={false}
        scroll={{ x: 1400 }}
        expandable={expandable}
        onRow={onRow}
      />

      {selectedProposal && (
        <ProposalDetailsModal
          isOpen={showProposalDetailsModal}
          onClose={onDetailProposalClose}
          shipment={shipment}
          selectedProposal={selectedProposal}
          currency={company?.currency}
          onEditClick={onDetailProposalEditClick}
          onReawardClick={onReawardClick}
        />
      )}

      {selectedProposal && (
        <ProposalEditModal
          isOpen={showProposalEditModal}
          onClose={onEditProposalClose}
          isFieldEditDisabled={isFieldEditDisabled}
          shipment={shipment}
          selectedProposal={selectedProposal}
        />
      )}
    </>
  );
};
