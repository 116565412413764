import {
  IDeleteBulkShipmentTagPayload,
  IShipementTags,
  IUpdateBulkShipmentTagPayload,
  IUpdateShipmentTagPayload,
} from '../types/entities/metadata.entity';
import { api } from './base-api';

export const shipmentMetaDataApi = api.injectEndpoints({
  endpoints: (build) => ({
    getShipmentTagsByAI: build.query<IShipementTags[], string>({
      query: (shipmentId) => `/shipment-metadata/suggestions/${shipmentId}`,
      providesTags: ['ShipmentTagsByAI'],
    }),

    createShipmentTagsByAI: build.mutation<IShipementTags[], string>({
      query: (shipmentId) => ({
        url: `/shipment-metadata/extract-tags/${shipmentId}`,
        method: 'POST',
      }),
      invalidatesTags: ['ShipmentTagsByAI'],
    }),

    bulkUpdateShipmentTagsByAI: build.mutation<
      void,
      IUpdateBulkShipmentTagPayload[]
    >({
      query: (body) => ({
        url: `shipment-metadata`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['ShipmentTagsByAI', 'ShipmentTags'],
    }),

    bulkDeleteShipmentTagsByAI: build.mutation<
      void,
      IDeleteBulkShipmentTagPayload
    >({
      query: (body) => ({
        url: `shipment-metadata/bulkDelete`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['ShipmentTagsByAI'],
    }),
  }),
});

export const {
  useGetShipmentTagsByAIQuery,
  useLazyGetShipmentTagsByAIQuery,
  useBulkUpdateShipmentTagsByAIMutation,
  useCreateShipmentTagsByAIMutation,
  useBulkDeleteShipmentTagsByAIMutation,
} = shipmentMetaDataApi;
