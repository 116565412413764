export const downloadZipFileFromBuffer = (
  bufferData: Uint8Array,
  fileName: string
) => {
  // Create a blob from the buffer data
  const blob = new Blob([bufferData], { type: 'application/zip' });

  // Create a link element
  const link = document.createElement('a');

  // Set the download attribute with the provided file name
  link.href = URL.createObjectURL(blob);
  link.download = fileName;

  // Append the link to the document body (required for Firefox)
  document.body.appendChild(link);

  // Trigger the download by simulating a click event
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
};
