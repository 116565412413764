import {
  IContainerEntity,
  ICreateContainerRequestBody,
  IDeleteContainerRequestBody,
  ICreateShipmentsFromTrackingRequestBody,
  ICreateShipmentsFromTrackingResponse,
  IUpdateContainerRequestBody,
  IBookingContainerEntity,
} from 'types/entities/container.entity';

import { api } from './base-api';

export const containerApi = api.injectEndpoints({
  endpoints: (build) => ({
    getContainers: build.query<IContainerEntity[], string>({
      query: (shipmentId) => `/shipment-container/${shipmentId}`,
      providesTags: ['ShipmentContainer'],
    }),

    getShipmentContainersList: build.query<IContainerEntity[], string>({
      query: (shipmentId) => `/shipment-container/list/${shipmentId}`,
    }),

    getBookingContainersList: build.query<IBookingContainerEntity[], string>({
      query: (bookingId) => `/booking-containers/${bookingId}`,
      providesTags: ['Bookings'],
    }),

    createContainer: build.mutation<
      IContainerEntity,
      ICreateContainerRequestBody
    >({
      query: (payload) => ({
        url: '/shipment-container',
        method: 'POST',
        body: {
          shipmentId: payload.shipmentId,
          ...payload.container,
        },
      }),
      invalidatesTags: [
        'ShipmentContainer',
        'ShipmentsById',
        'Tracking',
        'Bookings',
      ],
    }),

    updateContainer: build.mutation<
      IContainerEntity,
      IUpdateContainerRequestBody
    >({
      query: ({ shipmentId, containerId, status }) => ({
        url: `/shipment-container/${shipmentId}/${containerId}`,
        method: 'PATCH',
        body: { status },
      }),
      invalidatesTags: ['ShipmentContainer'],
    }),

    deleteContainer: build.mutation<string, IDeleteContainerRequestBody>({
      query: ({ containerIds, shipmentId }) => ({
        url: `/shipment-container/${shipmentId}`,
        method: 'DELETE',
        body: { containerIds },
      }),
      invalidatesTags: ['ShipmentContainer'],
    }),

    creatShipmentsFromTracking: build.mutation<
      ICreateShipmentsFromTrackingResponse,
      ICreateShipmentsFromTrackingRequestBody
    >({
      query: (trackingNumbers) => ({
        url: '/shipment/from-tracking',
        method: 'POST',
        body: trackingNumbers,
      }),
      invalidatesTags: ['ShipmentContainer', 'ShipmentsById', 'Tracking'],
    }),
  }),
});

export const {
  useGetContainersQuery,
  useGetShipmentContainersListQuery,
  useGetBookingContainersListQuery,
  useCreateContainerMutation,
  useUpdateContainerMutation,
  useDeleteContainerMutation,
  useLazyGetContainersQuery,
  useCreatShipmentsFromTrackingMutation,
} = containerApi;
