import { UserAddOutlined } from '@ant-design/icons';
import { Form, Select, Row, Col, DatePicker, Input } from 'antd';
import { useGetUserConnectionsQuery } from 'api/user';
import AddConnectionSelect from 'app/components/Connections/AddConnectionSelect';
import { displayFreighMethod } from 'app/components/ShipmentCreation/ShipmentCreationForm/freightMethod';
import LocationFields from 'app/design-system/LocationFields';
import React, { FC } from 'react';
import { IUserConnectionEntity } from 'types/entities/company.entity';
import { IncotermsEnum } from 'types/entities/product.entity';
import { PurchaseOrderStatusEnum } from 'types/entities/purchase-order.entity';
import { ModalityEnum } from 'types/entities/shipment.entity';
import { UserConnectionTypes } from 'types/entities/user.entity';
import { IOrderDetailsFormProps } from 'types/feature/purchase-orders.types';

import { splitState } from 'utils/format-fields';
import { computeUserNameWithCompany } from 'utils/user-utils';

const orderStatus = Object.values(PurchaseOrderStatusEnum);
const incoterms = Object.values(IncotermsEnum);
const modesOfTransportation = Object.values(ModalityEnum);

const computeSelectOptions = (supplier: IUserConnectionEntity[]) => {
  return supplier?.map((user) => {
    const { fullname, company } = user.partner;
    return {
      label: computeUserNameWithCompany({ fullname, company }),
      value: user.partner.id,
    };
  });
};

const OrderDetailsForm: FC<IOrderDetailsFormProps> = ({
  form,
  defaultValues,
  createBookingFlow,
}) => {
  const { data: suppliers } = useGetUserConnectionsQuery({
    type: UserConnectionTypes.SUPPLIER,
  });

  return (
    <Form
      layout="vertical"
      form={form}
      scrollToFirstError
      {...(defaultValues && { initialValues: defaultValues })}
    >
      <Row gutter={[12, 12]} align={'bottom'}>
        <Col span={8}>
          <Form.Item
            label="Supplier"
            name="supplier"
            rules={[
              {
                required: true,
                message: 'Please select a supplier.',
              },
            ]}
          >
            {AddConnectionSelect({
              selectOptions: computeSelectOptions(suppliers ?? []),
              isRoleHidden: true,
              showAddNewUser: true,
              btnProps: {
                icon: <UserAddOutlined />,
              },
            })}
          </Form.Item>
        </Col>
        {!createBookingFlow && (
          <>
            <Col span={8}>
              <Form.Item
                label="Incoterm"
                name="incoterms"
                rules={[
                  {
                    required: true,
                    message: 'Please select the incoterms.',
                  },
                ]}
              >
                <Select
                  placeholder="Choose Incoterm"
                  options={incoterms.map((value: IncotermsEnum) => {
                    return { value: value, label: value.toUpperCase() };
                  })}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Transportation mode"
                name="modality"
                rules={[
                  {
                    required: true,
                    message: 'Please select the mode of transportation.',
                  },
                ]}
              >
                <Select
                  placeholder="Choose the transportation's mode"
                  options={modesOfTransportation.map((value: ModalityEnum) => {
                    return {
                      value: value,
                      label: displayFreighMethod(value),
                    };
                  })}
                ></Select>
              </Form.Item>
            </Col>
          </>
        )}

        <Col span={8}>
          <Form.Item
            label="Order Date"
            name="orderIssueDate"
            rules={[
              {
                required: true,
                message: 'Please choose the order issue date',
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
        </Col>
        {!createBookingFlow && (
          <Col span={8}>
            <Form.Item
              label="Cargo Ready Date"
              name="cargoReadyDate"
              rules={[
                {
                  required: true,
                  message: 'Please choose the  estimated cargo ready  date',
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
        )}
        <Col span={8}>
          <Form.Item
            label="Status"
            name="status"
            rules={[
              {
                required: true,
                message: 'Please select the order status.',
              },
            ]}
          >
            <Select
              placeholder="Choose order status"
              options={orderStatus.map((value: PurchaseOrderStatusEnum) => {
                return { value: value, label: splitState(value) };
              })}
            ></Select>
          </Form.Item>
        </Col>

        {!createBookingFlow && (
          <LocationFields
            form={form}
            isEditView={false}
            defaultLocations={
              defaultValues
                ? {
                    origin: defaultValues.origin,
                    destination: defaultValues.destination,
                  }
                : null
            }
            filedsName={[
              'origin',
              'destination',
              'originFull',
              'destinationFull',
            ]}
            isLocationOptional
          />
        )}
        <Col span={8}>
          <Form.Item label="Reference" name="reference">
            <Input placeholder="Reference ex. XYZ-123" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default OrderDetailsForm;
