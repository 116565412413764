import { useJsApiLoader } from '@react-google-maps/api';
import { AutoComplete, Col, Form, Input, notification } from 'antd';
import { InternalNamePath, NamePath } from 'antd/es/form/interface';
import { isUserWithinTargetTimeZone } from 'api/common/config';
import {
  useLazyGetPlaceDetailsQuery,
  useLazyGetPlacesPredictionQuery,
} from 'api/locations';
import errorsLogger from 'app/layout/ErrorBoundary/useErrorReport';
import React, { FC, useEffect, useRef, useState } from 'react';
import { IPlaceData } from 'types/entities/location.entity';
import { ILocation } from 'types/entities/shipment.entity';
import { IPlaceOption } from 'types/feature/create-shipment.types';
import { ILocationFieldsProps } from 'types/feature/location-fields.type';

import { debounce } from 'utils/debounce';
import { displayErrors } from 'utils/error-notification';

import { mapConfig } from '../map';
import { buildLocation } from './locations.utils';

const { Item } = Form;

const isFieldOrigin = (field: NamePath) =>
  field === 'origin' ||
  ((field as InternalNamePath).length && field[1] === 'origin');

const LocationFields: FC<ILocationFieldsProps> = ({
  form,
  isEditView = false,
  defaultLocations: defaultValues,
  colSpan = 8,
  filedsName = ['origin', 'destination', 'originFull', 'destinationFull'],
  groupField,
  isLocationOptional,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const placesAutocompleteService = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const placesService = useRef<any>();
  const { isLoaded, loadError } = useJsApiLoader(mapConfig);

  // state
  const [placesOptions, setPlacesOptions] = useState<IPlaceOption[]>([]);

  // external APIs
  const [getServerPlacePredictions, { data: places, isSuccess }] =
    useLazyGetPlacesPredictionQuery();
  const [getServerPlaceDetails] = useLazyGetPlaceDetailsQuery();

  // google places API (Client Library)
  // TODO : REMOVE LATER IF IT IS NO LONGER NEEDED
  // const { placesService, placesAutocompleteService } = usePlacesService({
  //   apiKey: GOOGLE_MAPS_API_KEY,
  // });

  useEffect(() => {
    if (isLoaded && google) {
      placesAutocompleteService.current =
        new google.maps.places.AutocompleteService();

      placesService.current = new google.maps.places.PlacesService(
        document.createElement('div')
      );
    }
  }, [isLoaded]);

  useEffect(() => {
    if (loadError) {
      notification.error({
        message: 'Error while loading Location services !',
      });
      errorsLogger.report(
        `Location Fields Error :  ${loadError.name} / ${loadError.message} , ${loadError.cause}`
      );
    }
  }, [loadError]);

  // handle location values ( update form fields)
  const setLocationField = async (
    field: NamePath,
    location: ILocation,
    address: string
  ) => {
    if (groupField) {
      const fieldName = [groupField, ...(field as InternalNamePath)];
      form.setFieldValue(fieldName, address);
      if (isFieldOrigin(field)) {
        form.setFieldValue(
          [groupField, ...(filedsName[2] as InternalNamePath)],
          location
        );
      } else {
        form.setFieldValue(
          [groupField, ...(filedsName[3] as InternalNamePath)],
          location
        );
      }
      form.validateFields([fieldName]);
    } else {
      form.setFieldValue(field, address);
      if (isFieldOrigin(field)) {
        form.setFieldValue(filedsName[2], location);
      } else {
        form.setFieldValue(filedsName[3], location);
      }
      form.validateFields(filedsName);
    }
  };

  const setupLocationFields = (
    field: NamePath,
    place: google.maps.places.PlaceResult | null
  ) => {
    const location = buildLocation(place, {
      latitude: place?.geometry?.location?.lat() ?? 0,
      longitude: place?.geometry?.location?.lng() ?? 0,
    });
    setLocationField(field, location, place?.formatted_address || '');
  };

  const setupLocationFieldsExternal = (
    field: NamePath,
    place: IPlaceData | null
  ) => {
    const location = buildLocation(place, {
      latitude: place?.geometry.location.lat,
      longitude: place?.geometry.location.lng,
    });
    setLocationField(field, location, place?.formatted_address || '');
  };

  const getPlaceDetails = async (value: string, field: NamePath) => {
    const placeId = placesOptions.find((item) => item.value === value)?.key;
    if (placeId) {
      if (isUserWithinTargetTimeZone()) {
        await getServerPlaceDetails({ placeId: placeId })
          .unwrap()
          .then((place) => {
            setupLocationFieldsExternal(field, place);
          })
          .catch((err) => {
            displayErrors(err, { title: 'Place details Error' });
          });
      } else {
        placesService?.current?.getDetails(
          {
            placeId: placeId,
          },
          (
            place: google.maps.places.PlaceResult | null,
            statusCode: google.maps.places.PlacesServiceStatus
          ) => {
            if (statusCode === google.maps.places.PlacesServiceStatus.OK)
              setupLocationFields(field, place);
          }
        );
      }
    }
  };

  const getPlaceFromAddr = (addr: string, field: string | NamePath) => {
    placesService?.current?.textSearch(
      { query: addr },
      (places, statusCode) => {
        if (statusCode === google.maps.places.PlacesServiceStatus.OK) {
          const placeId = places?.length && places[0].place_id;

          if (!places?.length) {
            notification.warning({
              message: `${field} address detection`,
              description:
                "Couldn't find a matching address from text , please type a diffrent address and pick one from suggestions !",
            });
          }
          if (placeId) {
            placesService?.current?.getDetails(
              {
                placeId: placeId,
              },
              (
                place: google.maps.places.PlaceResult | null,
                statusCode: google.maps.places.PlacesServiceStatus
              ) => {
                if (statusCode === google.maps.places.PlacesServiceStatus.OK)
                  setupLocationFields(field, place);
              }
            );
          }
        }
      }
    );
  };

  useEffect(() => {
    if (!isEditView && defaultValues) {
      const { destination, origin } = defaultValues;
      if (destination) {
        getPlaceFromAddr(destination, filedsName[1]);
      }
      if (origin) {
        getPlaceFromAddr(origin, filedsName[0]);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  // hooks
  const mapPlacesOptions = (
    placePredictions: google.maps.places.AutocompletePrediction[]
  ) => {
    setPlacesOptions(
      placePredictions.map(
        (value: google.maps.places.AutocompletePrediction) => {
          return {
            value: value.description,
            label: value.description,
            key: value.place_id,
          };
        }
      )
    );
  };

  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (isSuccess && places?.length) {
      setPlacesOptions(
        places.map((value: google.maps.places.AutocompletePrediction) => {
          return {
            value: value.description,
            label: value.description,
            key: value.place_id,
          };
        })
      );
    }
  }, [places, isSuccess]);

  // debounce places search in order to avoid api calls on each key stroke
  const debouncedSearch = debounce(async (value: string) => {
    if (value === '') return;
    await getServerPlacePredictions({
      search: value,
    });
  }, 300);

  // search directly thorugh google places api
  const onPlacesSearch = async (value: string) => {
    try {
      const response =
        await placesAutocompleteService?.current?.getPlacePredictions({
          input: value,
        });

      if (response?.predictions) mapPlacesOptions(response.predictions);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Places Search Error' });
    }
  };

  const onSearchByText = async (value: string) => {
    // through BE apis
    if (isUserWithinTargetTimeZone()) {
      debouncedSearch(value);
    } else {
      // directly thorugh google places api
      if (value) onPlacesSearch(value);
    }
  };

  return (
    <>
      <Col span={colSpan}>
        <Item
          name={filedsName[2]}
          rules={[
            {
              required: !isLocationOptional,
            },
          ]}
          hidden
        >
          <Input />
        </Item>
        <Item
          name={filedsName[3]}
          rules={[
            {
              required: !isLocationOptional,
            },
          ]}
          hidden
        >
          <Input />
        </Item>
        <Item
          label="From"
          name={filedsName[0]}
          rules={[
            {
              required: !isLocationOptional,
              message: 'Please enter the origin country.',
            },
            () => ({
              validator(rule, value) {
                if (isLocationOptional && !value) {
                  return Promise.resolve();
                }
                const fieldName =
                  typeof filedsName[2] === 'string'
                    ? filedsName[2]
                    : ([
                        groupField,
                        ...(filedsName[2] as InternalNamePath),
                      ] as NamePath);
                const originFull = form.getFieldValue(fieldName);
                if (originFull?.country) {
                  return Promise.resolve();
                }

                return Promise.reject('Please select valid a address.');
              },
            }),
          ]}
          shouldUpdate
        >
          <AutoComplete
            placeholder="Origin"
            onSearch={onSearchByText}
            options={placesOptions}
            onSelect={(value) => {
              getPlaceDetails(value, filedsName[0]);
            }}
          />
        </Item>
      </Col>
      <Col span={colSpan}>
        <Item
          label="To"
          name={filedsName[1]}
          rules={[
            {
              required: !isLocationOptional,
              message: 'Please enter the destination country.',
            },
            () => ({
              validator(rule, value) {
                if (isLocationOptional && !value) {
                  return Promise.resolve();
                }
                const fieldName =
                  typeof filedsName[3] === 'string'
                    ? filedsName[3]
                    : ([
                        groupField,
                        ...(filedsName[3] as InternalNamePath),
                      ] as NamePath);
                const destinationFull = form.getFieldValue(fieldName);
                if (destinationFull?.country) {
                  return Promise.resolve();
                }

                return Promise.reject('Please select valid a address.');
              },
            }),
          ]}
        >
          <AutoComplete
            placeholder="Destination"
            onSearch={onSearchByText}
            options={placesOptions}
            onSelect={(value) => getPlaceDetails(value, filedsName[1])}
          />
        </Item>
      </Col>
    </>
  );
};

export default LocationFields;
