import { Typography, Tooltip, Tag, Space } from 'antd';
import { computeTotalFees } from 'app/components/Proposal/Fees/fees.const';
import { formatShipmentAmount } from 'app/components/ShipmentCreation/ShipmentCreationForm/shipment.utils';
import dayjs from 'dayjs';
import dayJs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { CurrencyEnum } from 'types/entities/fee.entity';
import { IProposalEntity } from 'types/entities/proposal.entity';
import { TProposalTableColumnsProps } from 'types/feature/proposals.types';

import { computeTagColors } from 'utils/format-fields';
import { dateFormat2Const } from 'utils/shipment-type';

const { Text } = Typography;

export const proposalTableColumns: TProposalTableColumnsProps = (
  currency = CurrencyEnum.USD,
  defaultShipmentAmount
) => {
  return [
    {
      title: 'Submitted By',
      dataIndex: 'submited-by',
      key: 'submited-by',
      render: (_, proposal: IProposalEntity) => {
        return (
          <Space direction="vertical">
            <Text strong>{proposal.company?.name}</Text>
            {proposal.submittedByUser && (
              <Text type="secondary">{proposal.submittedByUser?.fullname}</Text>
            )}
            {proposal.contractId && (
              <Link
                onClick={(e) => e.stopPropagation()} // avoid openng the quotes details
                to="/contracts"
                state={{ activeTab: 'contracts' }}
              >
                Contract Rate
              </Link>
            )}
          </Space>
        );
      },
      sorter: (a, b) => a.company?.name?.localeCompare(b.company.name),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_, proposal) => proposal.shipmentType.title.toUpperCase(),

      sorter: (a, b) =>
        a.shipmentType.title?.localeCompare(b.shipmentType.title),
    },
    {
      title: 'Shipment Amount',
      dataIndex: 'shipmentAmount',
      key: 'shipmentAmount',
      render: (_, proposal) => {
        if (proposal.proposalShipmentItems.length) {
          return formatShipmentAmount(
            proposal.shipmentType,
            proposal.proposalShipmentItems,
            proposal.proposalShipmentItemsFormatted
          );
        } else {
          return defaultShipmentAmount;
        }
      },
    },
    {
      title: 'Vessel',
      dataIndex: 'vesselName',
      key: 'vessel',
      render: (_, proposal) => (
        <Tooltip title={proposal.vesselName}>
          <Text ellipsis={true} style={{ width: 100 }}>
            {proposal.vesselName}
          </Text>
        </Tooltip>
      ),
      sorter: (a, b) => a.vesselName?.localeCompare(b.vesselName),
    },
    {
      title: 'Carrier',
      dataIndex: 'carrier',
      key: 'carrier',
      render: (_, proposal) => proposal.carrier?.name,
    },
    {
      title: 'Routing',
      dataIndex: 'routing',
      key: 'routing',
      filters: [
        {
          value: 'direct',
          text: 'Direct',
        },
        {
          value: 'in-direct',
          text: 'Indirect',
        },
      ],
      // eslint-disable-next-line react/display-name
      onFilter: (value: any, { routing }: IProposalEntity) =>
        !!routing && routing.indexOf(value) === 0,
    },
    {
      title: 'Trans Shipment Port ',
      dataIndex: 'transShipmentPort',
      key: 'transShipmentPort',
      render: (_, proposal) => <Text>{proposal.transShipmentPort}</Text>,
    },

    {
      title: 'Expiry Date',
      key: 'expiry-day',
      dataIndex: 'expiry-day',

      render: (_, proposal) => (
        <Text>
          {proposal.expirationDate
            ? dayJs(proposal.expirationDate).format(dateFormat2Const)
            : '--'}
        </Text>
      ),
    },
    {
      title: 'Transit Time',
      key: 'transit-time',
      dataIndex: 'transit-time',
      render: (_, proposal) => (
        <Text>
          {proposal.transitTime ? `${proposal.transitTime} days` : '--'}
        </Text>
      ),

      sorter: (a, b) => a.transitTime - b.transitTime,
    },
    {
      title: 'Payment Term',
      key: 'payment-term',
      dataIndex: 'payment-',
      render: (_, proposal) => (
        <Text>
          {proposal.paymentTerms ? `${proposal.paymentTerms} days` : '--'}
        </Text>
      ),
      sorter: (a, b) => a.paymentTerms - b.paymentTerms,
    },
    {
      title: 'ETA',
      key: 'eta',
      dataIndex: 'eta',

      render: (_, proposal) =>
        proposal.eta ? dayjs(proposal?.eta).format(dateFormat2Const) : '--',
    },
    {
      title: 'ETD',
      key: 'etd',
      dataIndex: 'etd',

      render: (_, proposal) =>
        proposal.etd ? dayjs(proposal?.etd).format(dateFormat2Const) : '--',
    },
    {
      title: 'Total Cost',
      key: 'total-cost',
      dataIndex: 'total-cost',

      render: (_, { proposalItems, proposalCost, previousBiddingPrice }) => (
        <Tooltip title={computeTotalFees(proposalItems)}>
          <Space direction="vertical" size={[1, 1]}>
            {proposalCost && (
              <Text strong>
                {proposalCost} {currency?.toUpperCase()}
              </Text>
            )}
            {previousBiddingPrice && (
              <Text delete type="secondary">
                {previousBiddingPrice} {currency?.toUpperCase()}
              </Text>
            )}
          </Space>
        </Tooltip>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 100,
      render: (_, proposal) => (
        <Tag
          color={computeTagColors(proposal.status)}
          style={{ marginBottom: '10px', textTransform: 'capitalize' }}
        >
          {proposal.status}
        </Tag>
      ),
      align: 'center',
    },
  ];
};

export const columnsOptions = proposalTableColumns().map((column) => ({
  value: column.key as string,
  label: column.title as string,
}));

export const quickViewColumns = [
  'submited-by',
  'status',
  'total-cost',
  'type',
  'expiry-day',
  'transit-time',
];
