import { Popconfirm, Space, Typography } from 'antd';
import Table, { ColumnType } from 'antd/es/table';
import React from 'react';
import {
  IPurchaseOrderItemEntity,
  IPurchaseOrderItemShipmentsEnity,
} from 'types/entities/purchase-order.entity';

import { convertSlugToShipmentId } from 'utils/slug-formattor';

type EditableTableProps = Parameters<typeof Table>[0];

export type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export const filteredItemsClumns = [
  'ref',
  'product items',
  'requested',
  'booked',
];
export const itemsColumns = [
  { value: 'ref', label: 'SN #' },
  { value: 'weight', label: 'Weight' },
  { value: 'volume', label: 'Volume' },
  { value: 'shipments', label: 'Shipments' },
  { value: 'description', label: 'Product Items' },
  { value: 'uom', label: 'UoM' },
  { value: 'price', label: 'Price' },
  { value: 'tax', label: 'Tax' },
  { value: 'totalAmount', label: 'Total Price' },
  { value: 'boxes', label: 'Boxes' },
  { value: 'requested', label: 'Requested' },
  { value: 'booked', label: 'Booked' },
  { value: 'coo', label: 'Country of Origin' },
  { value: 'hsCode', label: 'HS Code' },
];

const displayShipmentList = (shipments: IPurchaseOrderItemShipmentsEnity[]) =>
  shipments.length > 0 && (
    <Space direction="vertical" size={[2, 2]}>
      {shipments?.map((sh) =>
        sh ? (
          <Space size={[6, 6]} key={sh.id}>
            <Typography.Link
              key={sh.id}
              href={`/shipments/${convertSlugToShipmentId(sh.slug)}`}
              className="poi-slug-text"
              ellipsis
            >
              {convertSlugToShipmentId(sh.slug)}
            </Typography.Link>
            <Typography.Text
              className="poi-ref-text"
              ellipsis={{ tooltip: ` ${sh.shipmentReference}` }}
            >
              {sh.shipmentReference ? ` ${sh.shipmentReference}` : ''}
            </Typography.Text>
          </Space>
        ) : (
          ''
        )
      )}
    </Space>
  );

export const POsColumnConst: (ColumnType<IPurchaseOrderItemEntity> & {
  editable?: boolean;
  dataIndex: string;
})[] = [
  {
    title: 'SN #',
    key: 'ref',
    dataIndex: 'reference',
  },
  {
    title: 'Product Items',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'HS Code',
    dataIndex: 'hsCode',
    key: 'hsCode',
  },
  {
    title: 'Country of Origin',
    dataIndex: 'coo',
    key: 'coo',
  },
  {
    title: 'Shipments',
    dataIndex: 'shipment',
    key: 'shipments',
    width: '5%',

    render: (_, record: IPurchaseOrderItemEntity) =>
      record.shipments.length > 1 ? (
        <Popconfirm
          title="Associated shipments"
          description={displayShipmentList(record.shipments.slice(1))}
          okText="OK"
          placement="topRight"
          showCancel={false}
        >
          <Space wrap>
            {displayShipmentList(record.shipments.slice(0, 1))}
            <Typography.Link>+ More</Typography.Link>
          </Space>
        </Popconfirm>
      ) : (
        displayShipmentList(record.shipments)
      ),
  },

  {
    title: 'UoM',
    key: 'uom',

    dataIndex: 'unitOfMeasurement',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',

    render: (_, record: IPurchaseOrderItemEntity) =>
      `${record.unitPrice ?? '--'}`,
  },
  {
    title: 'Tax',
    dataIndex: 'tax',
    key: 'tax',
  },
  {
    title: 'Total Price',
    dataIndex: 'totalAmount',
    key: 'totalAmount',

    render: (_, record: IPurchaseOrderItemEntity) => {
      return `${(
        (record.unitPrice ?? 0) * (record.requestedQuantity ?? 0)
      ).toFixed(1)} ${
        record.currencyCode ? record.currencyCode?.toUpperCase() : ''
      }`;
    },
  },
  {
    title: 'Weight',
    dataIndex: 'totalWeight',
    key: 'weight',
    className: 'items-table__row__qte',
  },
  {
    title: 'Volume',
    key: 'volume',
    dataIndex: 'totalVolume',
  },
  {
    title: 'Boxes',
    key: 'boxes',
    dataIndex: 'boxes',
    className: 'items-table__row__qte',
  },
  {
    title: 'Requested',
    key: 'requested',
    dataIndex: 'requestedQuantity',
  },
  {
    title: 'Booked',
    dataIndex: 'booked',
    key: 'booked',

    render: (_, record: IPurchaseOrderItemEntity) => (
      <Space direction="vertical" size={[1, 1]}>
        <Typography.Text>{record.bookedQuantity}</Typography.Text>
        <Typography.Text type="secondary" className="row-sub-text">
          {record.requestedQuantity
            ? (
                ((record.bookedQuantity ?? 0) / record.requestedQuantity) *
                100
              ).toFixed(1)
            : '--'}
          %
        </Typography.Text>
      </Space>
    ),
  },
];
