import { Space, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ShipmentReference from 'app/design-system/ShipmentReference';
import TableDescriptionList from 'app/design-system/TableDescriptionList';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { IInvoiceEntity } from 'types/entities/invoice.entity';
import { SearchAndSortParamsInvoice } from 'types/feature/pagination.types';

import { convertSlugToShipmentId } from 'utils/slug-formattor';
import { computeFullName } from 'utils/user-utils';

import { ReactComponent as BiggerIcon } from '../../../../../assets/icons/amount-bigger-icon.svg';
import { ReactComponent as EqualIcon } from '../../../../../assets/icons/amount-equal.svg';
import { ReactComponent as SmallerIcon } from '../../../../../assets/icons/amount-smaller-icon.svg';

const renderIcon = (
  quotedAmountNumber: number,
  invoiceAmountNumber: number
) => {
  const { title, Icon } = (() => {
    return quotedAmountNumber > invoiceAmountNumber
      ? {
          title: 'Quoted amount is higher than invoiced amount.',
          Icon: BiggerIcon,
        }
      : quotedAmountNumber < invoiceAmountNumber
      ? {
          title: 'Invoiced amount is higher than quoted amount.',
          Icon: SmallerIcon,
        }
      : {
          title: 'Invoiced amount is equal to quoted amount.',
          Icon: EqualIcon,
        };
  })();

  return (
    <Tooltip title={title}>
      <Icon />
    </Tooltip>
  );
};

export const invoiceListTable: ColumnsType<IInvoiceEntity> = [
  {
    title: 'Invoice No',
    dataIndex: 'invoiceNo',
    key: 'invoiceNo',
    sorter: true,
    render: (_, record) => (
      <Space direction="vertical">
        <Link
          to={`/shipments/SH-${record.shipment.slug}/invoice/${record.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {record.slug}
        </Link>
        <Typography.Text strong>{record.invoiceNo}</Typography.Text>
      </Space>
    ),
  },
  {
    title: 'Shipment ID',
    dataIndex: 'shipmentId',
    key: 'shipment-id',
    sorter: true,
    render: (_, record) => (
      <TableDescriptionList
        label={convertSlugToShipmentId(record?.shipment?.slug) || ''}
        description={
          <ShipmentReference
            isSecondary
            reference={record?.shipment?.shipmentReference || ''}
            className=""
          />
        }
        isVertical={true}
      />
    ),
  },
  {
    title: 'Submitted By',
    dataIndex: 'submitted-by',
    key: 'submitted-by',
    render: (_, record) => (
      <TableDescriptionList
        label={record.createdBy?.company.name || ''}
        description={computeFullName(record?.createdBy)}
        isVertical={true}
      />
    ),
  },
  {
    title: 'Vendor',
    dataIndex: 'vendor',
    key: 'vendor',
    sorter: true,
    render: (_, record) => record?.vendor?.name?.toUpperCase() || '',
  },
  {
    title: 'Assigned to',
    dataIndex: 'assignee',
    key: 'assignee',
    render: (_, record) => {
      const assignee = record.assignee
        ? computeFullName(record.assignee)
        : 'unassigned';
      return (
        <TableDescriptionList
          label={record.assignee?.company?.name || ''}
          description={assignee}
          isVertical={true}
        />
      );
    },
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    render: (_, record) =>
      record?.currency ? record?.currency.toUpperCase() : '',
  },
  {
    title: 'Total Tax',
    dataIndex: 'totalTax',
    key: 'totalTax',
    render: (_, record) => record?.totalTax || 0,
  },
  {
    title: 'Quoted Amount',
    dataIndex: 'quotedAmount',
    key: 'quotedAmount',
    render: (_, record) =>
      record.proposalTotalCost ? (
        <div className="quoted-amount-wrapper">
          <div>{record?.proposalTotalCost?.toString() || ''}</div>
          {record.totalCost &&
            renderIcon(record.proposalTotalCost, record.totalCost)}
        </div>
      ) : (
        ''
      ),
  },
  {
    title: 'Invoice Amount',
    dataIndex: 'totalCost',
    key: 'totalCost',
    render: (_, record) =>
      record?.totalCost ? record?.totalCost?.toString() : '',
  },
  {
    title: 'Variance',
    dataIndex: 'variance',
    key: 'variance',
    render: (_, record) => record.invoiceProposalCostDifference ?? '',
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: true,
    render: (_, record) =>
      record.createdAt ? dayjs(record.createdAt).format('DD-MM-YYYY') : '',
  },
  {
    title: 'Invoice Date',
    sorter: true,
    dataIndex: 'issueDate',
    key: 'issueDate',
    render: (_, record) =>
      record.issueDate ? dayjs(record.issueDate).format('DD-MM-YYYY') : '',
  },
  {
    title: 'Supplier VAT',
    sorter: true,
    dataIndex: 'supplierVatNo',
    key: 'supplierVatNo',
  },
  {
    title: 'Buyer VAT',
    sorter: true,
    dataIndex: 'buyerVatNo',
    key: 'buyerVatNo',
  },
];

export const defaultSelectedColumns = [
  'invoiceNo',
  'shipment-id',
  'vendor',
  'assignee',
  'currency',
  'totalCost',
  'quotedAmount',
  'variance',
  'createdAt',
  'issueDate',
  'status',
  'attachment',
  'actions',
];

export const defaultPaginationAndSorting: SearchAndSortParamsInvoice = {
  page: 1,
  limit: 25,
  sortBy: 'invoiceNo',
  sortOrder: 'DESC',
  keyword: '',
};
