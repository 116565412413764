import { Col, Empty, Row, Space, Spin, Tag, Timeline, Typography } from 'antd';
import { useGetHistoryByPrposalIdQuery } from 'api/proposal';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { IProposalHistoryProps } from 'types/feature/proposal.types';

import { computeTagColors } from 'utils/format-fields';

import './proposal-history.scss';

const { Text } = Typography;

const ProposalHistory: FC<IProposalHistoryProps> = ({ proposalId }) => {
  const { data: historyData, isLoading } =
    useGetHistoryByPrposalIdQuery(proposalId);
  return (
    <>
      {isLoading ? (
        <Spin className="center" />
      ) : historyData && historyData.data.proposalHistory.length > 0 ? (
        <Timeline
          className="history-timeline"
          items={historyData.data.proposalHistory.map((item) => {
            return {
              children: (
                <Row justify="space-between">
                  <Col>
                    <Space direction="vertical">
                      <Text>{item.createdBy.fullname} set the status to</Text>
                      <Tag
                        className="status-history-tag"
                        color={computeTagColors(item.status)}
                      >
                        {item.status}
                      </Tag>
                    </Space>
                  </Col>
                  <Col>
                    {dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                  </Col>
                </Row>
              ),
            };
          })}
        />
      ) : (
        <Empty description="no proposal history found" />
      )}
    </>
  );
};

export default ProposalHistory;
