import {
  Button,
  Row,
  Select,
  Typography,
  Upload,
  UploadProps,
  message,
  notification,
} from 'antd';
import ModalHeader from 'app/design-system/Modal/ModalHeader';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'state/reducer';
import { ICompanyConnectionEntity } from 'types/entities/company.entity';
import {
  IPOParsedDocumentEntity,
  IPurchaseOrderEntity,
} from 'types/entities/purchase-order.entity';

import { displayErrors } from 'utils/error-notification';

import { ReactComponent as EnterInfoIcon } from '../../../../assets/icons/enter-info-Icons.svg';
import { ReactComponent as UploadIcon } from '../../../../assets/iconsNew/upload-icon-outlined.svg';
import './style.scss';
import usePurchaseOrderUpload from './usePurchaseOrderUpload';
import {
  isExcel,
  mapJSONToPurchaseOrder,
} from './utils/upload-order-file.helper';

const { Text } = Typography;
const { Dragger } = Upload;

const computeCompanyOptions = (companies: ICompanyConnectionEntity[]) => {
  return companies?.map((company) => ({
    label: company.partner.name,
    value: company.partner.id,
  }));
};
interface IPurchaseOrderUploadScreenProps {
  canSelectSupplier: boolean;
  onModalClose: () => void;
  onParseBookingSuccess?: (extractedData: IPurchaseOrderEntity) => void;
}

const PurchaseOrderUploadScreen: React.FC<IPurchaseOrderUploadScreenProps> = ({
  canSelectSupplier,
  onParseBookingSuccess,
  onModalClose,
}) => {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.user);

  const [selectedCompany, setSelectedCompany] = useState<string | undefined>(
    undefined
  );

  const {
    uploadedFile,
    uploadedFileType,
    companyListData,
    isCompanyListLoading,
    isExtracting,
    extractOrder,
    uploadOrderFile,
    onUploadedFileChange,
  } = usePurchaseOrderUpload();

  const goToCreateOrderPage = () => {
    navigate('/purchase-orders/create');
    onModalClose();
  };

  const onParseSuccess = (extractedData: IPOParsedDocumentEntity) => {
    try {
      const PO = mapJSONToPurchaseOrder(extractedData);

      if (onParseBookingSuccess) {
        onParseBookingSuccess(PO as IPurchaseOrderEntity);
      } else if (uploadedFileType) {
        navigate('/purchase-orders/create', {
          state: {
            order: PO,
            files: !isExcel(uploadedFileType)
              ? [extractedData?.fileUrl, uploadedFile]
              : null,
          },
        });
      }
    } catch (error) {
      notification.error({ message: 'Error while parsing file !' });
    }
  };

  const onConfirm = async () => {
    if (uploadedFile && uploadedFileType && user) {
      try {
        const extractedDataResp = await extractOrder({
          fileLocation: uploadedFile,
          ...(selectedCompany ? { supplier: selectedCompany } : {}),
          fileType: uploadedFileType,
          companyId: user?.companyId,
        }).unwrap();
        onParseSuccess(extractedDataResp);
        onModalClose();
      } catch (error: any) {
        displayErrors(error, {
          title: 'Error while extracting data from PO file !',
        });
      }
    }
  };

  const props: UploadProps = {
    maxCount: 1,
    multiple: false,
    customRequest: uploadOrderFile,
    onRemove: (file) => {
      onUploadedFileChange(undefined);
    },
    onChange(info: any) {
      const { status } = info.file;

      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <>
      <ModalHeader
        title="Create a Purchase Order"
        subTitle="Upload a purchase order, or create one within Beebolt."
      />
      <div className="po-creation-screen">
        {canSelectSupplier && (
          <Select
            placeholder={'Select your supplier'}
            loading={isCompanyListLoading}
            options={computeCompanyOptions(companyListData?.data.items ?? [])}
            onSelect={(value, option) => setSelectedCompany(option.label)}
            style={{ width: '100%' }}
          />
        )}
        <div className="po-creation-screen_upload-btn">
          <Dragger {...props}>
            <div className="upload-file-wrapper">
              <UploadIcon />
              <Text className="upload-lebel">
                Select a Purchase Order File, or <br />
                Drag and Drop
              </Text>
            </div>
          </Dragger>
        </div>
        <Row justify="end">
          <Button
            onClick={onConfirm}
            disabled={!uploadedFile || isExtracting}
            loading={isExtracting}
          >
            Confirm
          </Button>
        </Row>
      </div>

      <div className="po-creation-screen">
        <div className="po-creation-screen_manual">
          <Typography.Paragraph>Or</Typography.Paragraph>
          <Button
            type="default"
            icon={<EnterInfoIcon />}
            data-chameleon="submit-ai-shipment-form"
            onClick={goToCreateOrderPage}
          >
            Enter information manually
          </Button>
        </div>
      </div>
    </>
  );
};

export default PurchaseOrderUploadScreen;
