import dayjs from 'dayjs';
import {
  AccessLevelEnum,
  IPurchaseOrderCollaborator,
  IPurchaseOrderCollaboratorEntity,
} from 'types/feature/purchase-orders.types';

import { IncotermsEnum } from './product.entity';
import { ILocation, ModalityEnum } from './shipment.entity';
import { IUserEntity } from './user.entity';

export enum PurchaseOrderStatusEnum {
  READY_PICKUP = 'ready_to_pickup',
  PICKED = 'order_picked',
  IN_PRODUCTION = 'in_production',
  CONFIRMED = 'confirmed',
}

export enum MeasurementUnitEnum {
  WEIGHT = 'weight',
  VOLUME = 'volume',
  QUANTITY = 'quantity',
}

export interface IPurchaseOrderItemShipmentsEnity {
  id: string;
  slug: string;
  shipmentReference: string;
}

export interface IPurchaseOrderItemEntity {
  id?: string;
  description?: string;
  unitPrice: number | null;
  taxRate: number;
  shipments: IPurchaseOrderItemShipmentsEnity[];
  requestedQuantity: number | null;
  bookedQuantity: number | null;
  weightPerUnit: number | null;
  volumePerUnit: number | null;
  boxes: number | null;

  toBook?: number; // used locally
  cargoReadyDate: dayjs.Dayjs | string;
  unitOfMeasurement: MeasurementUnitEnum;
  totalWeight?: number;
  totalVolume?: number;
  totalAmount?: number;
  currencyCode: string | null;
  taxAmount?: number;
  reference: string | null;
  order?: string;
  bookingItemId?: string | null;
  hsCode?: string | null;
  coo?: string | null;
}
export interface ICurrentUserAccessLevel {
  id: string;
  accessLevel: AccessLevelEnum;
  collaborator: IPurchaseOrderCollaboratorEntity;
}

export interface IPurchaseOrderEntity {
  id: string;
  createdBy: IPurchaseOrderCollaboratorEntity;
  createdAt: string;
  status: PurchaseOrderStatusEnum;
  slug: number;
  orderIssueDate: string;
  eta: string;
  etd: string;
  destination: ILocation;
  origin: ILocation;
  reference: string;
  attachments: string[];
  supplierId: string;
  supplier: IUserEntity;
  requested: number;
  booked: number;
  purchaseOrderCollaborators: IPurchaseOrderCollaborator[];

  modality: ModalityEnum;
  incoterms: IncotermsEnum;
  purchaseOrderItems: IPurchaseOrderItemEntity[];
  isDraft: boolean;
  totalBoxes: number;
  currentUserAccessLevel: ICurrentUserAccessLevel;
  owner: IPurchaseOrderCollaboratorEntity;
}

export interface IPurchaseOrderCreateBody {
  status: PurchaseOrderStatusEnum | null;
  slug: number;
  cargoReadyDate: dayjs.Dayjs;
  orderIssueDate: dayjs.Dayjs;
  destination: ILocation;
  origin: ILocation;
  reference: string;
  attachments: string[];
  supplier: string;
  modality: ModalityEnum;
  incoterms: IncotermsEnum;
  purchaseOrderItems: IPurchaseOrderItemEntity[];
  urls: string[];
  isDraft: boolean;
}

export interface IPOsResponseBody {
  items: IPurchaseOrderEntity[];
  meta?: {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

export enum SplitScreenMode {
  ONLY_PO = 'only-po',
  BOTH = 'both-po-booking',
  ONLY_BOOKING = 'only-booking',
}

export interface IPOParsedDocumentLineItemEntity {
  productCode: string;
  itemDeliveryDate: number;
  quantity: number;
  weight: number;
  volume: number;
  boxes: number;
  unitOfMeasure: string;
  unitPrice: number;
  taxAmount: number;
  amount: number;
  description: string;
  incoterm: IncotermsEnum;
}

export interface IPOParsedDocumentEntity {
  supplierName: string;
  currency: string;
  receiverName: string;
  purchaseOrderId: string;
  paymentTerms: string;
  purchaseOrderDate: number;
  cargoReadyDate: number;
  totalAmount: number;
  shipToAddress: string;
  lineItems: IPOParsedDocumentLineItemEntity[];
  fileUrl: string;
  origin: string;
  destination: string;
  status: PurchaseOrderStatusEnum;
  modality: ModalityEnum;
}

export enum POFileType {
  XLSX = 'xlsx',
  PDF = 'pdf',
  UNKNOWN = 'unknown',
}
export interface IPOParsingReq {
  supplier?: string;
  fileLocation: string;
  fileType: POFileType;
  companyId: string;
}

export interface IPurchaseOrderCollaboratorCreateBody {
  purchaseOrderId: string;
  collaborators: { collaboratorId: string; accessLevel: AccessLevelEnum }[];
}
export interface IPurchaseOrderCollaboratorUpdateBody {
  purchaseOrderId: string;
  collaboratorId: string;
  accessLevel: AccessLevelEnum;
}
