import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import {
  Dropdown,
  message,
  Input,
  Space,
  Tag,
  Select,
  Col,
  Row,
  Button,
  Form,
  theme,
  ColorPicker,
  Modal,
} from 'antd';
import { Color } from 'antd/es/color-picker';
import { useForm } from 'antd/es/form/Form';
import {
  useGetShipmentMetadataQuery,
  useGetAllCompanyShipmentTagsQuery,
  useAddMetadataToShipmentMutation,
  useDeleteMetadaShipmentMutation,
  useGetShipmentTagsValuesQuery,
  useCreateCompanyShipmentTagMutation,
} from 'api/company-metadata';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/reducer';
import { IShipmentHeaderProps } from 'types/feature/shipments.types';

import { isViewer } from 'utils/collaborators';
import { displayErrors } from 'utils/error-notification';
import { hsvToHex } from 'utils/hsvToHex-helper';
import { isAdmin } from 'utils/shipment-helper';
import {
  addShipmentTags,
  createShipmentTags,
} from 'utils/shipment-tags-helper';

import { useUpdateShipmentTagsValueMutation } from '../../../../api/company-metadata';
import { isDanube, isDanubeAccountTeam } from './helper';
import './shipment-tags.scss';

const ShipmentTags: React.FC<IShipmentHeaderProps> = ({ shipment }) => {
  const { token } = theme.useToken();
  const [form] = useForm();
  const [editTagForm] = Form.useForm<any>();

  const editInputRef = useRef<InputRef>(null);
  const user = useSelector((state: RootState) => state.user.user);
  const isUserAdmin = isAdmin(user?.userRole);

  const [shipmentTag, setShipmentTag] = useState<string>('');
  const [rows, setRows] = useState<{ type: string }[]>([{ type: 'addTag' }]);

  const [editInputValue, setEditInputValue] = useState<string>('');
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<any>(null);

  const [updateTagsById, { isLoading: isUpdateTagLoading }] =
    useUpdateShipmentTagsValueMutation();

  const { data: shipmentTags } = useGetShipmentMetadataQuery(shipment?.id, {
    skip: !shipment?.id,
  });

  const [
    createShipmentTag,
    {
      isLoading: createShipmentTagLoading,
      isError: tagIsError,
      error: tagError,
    },
  ] = useCreateCompanyShipmentTagMutation();
  const { data: companyTags } = useGetAllCompanyShipmentTagsQuery(
    {
      companyId: user?.companyId ?? '',
    },
    { skip: !user?.companyId }
  );

  const [addShipmentData, { isLoading: addShipmentDataLoading }] =
    useAddMetadataToShipmentMutation();
  const [deleteShipmentTag, { isError: isDeleteError, error: deleteError }] =
    useDeleteMetadaShipmentMutation();

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);

  const handleDeleteTag = async (removedTag: string) => {
    try {
      await deleteShipmentTag(removedTag).unwrap();
      message.success('Tag removed successfully');
      form.resetFields();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Error on removing tag' });
    }
  };

  const tagPlusStyle: React.CSSProperties = {
    height: 22,
    background: token.colorBgContainer,
    borderStyle: 'dashed',
  };

  const { data: shipmentTagValues } = useGetShipmentTagsValuesQuery(
    shipmentTag,
    {
      skip: !shipmentTag,
      refetchOnFocus: false,
    }
  );

  const onSelectShipmenTag = (value) => {
    const shipmentTagId = companyTags?.find(
      (item) => item.metadataKey === value
    )?.id;
    if (shipmentTagId) setShipmentTag(shipmentTagId);
  };

  useEffect(() => {
    if (isDeleteError) {
      displayErrors(deleteError, { title: 'Error deleting tag:' });
    }
  }, [isDeleteError, deleteError]);

  useEffect(() => {
    if (tagIsError) displayErrors(tagError, { title: 'Error creating tag:' });
  }, [tagIsError, tagError]);

  const handleAddNewCreateRow = (rowIndex: number) => {
    const updatedRows = rows.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          type: 'createTag',
        };
      }
      return row;
    });

    setRows(updatedRows);
  };

  const handleAddNewRow = () => {
    setRows([
      ...rows,
      {
        type: 'addTag',
      },
    ]);
  };

  const handleRemoveRow = (rowIndex: number) => {
    const updatedRows = [...rows];
    updatedRows.splice(rowIndex, 1);
    setRows(updatedRows);
  };

  const handleCloseButtonClick = () => {
    // add Close button to the dropdown
    setIsDropdownVisible(false);
  };

  const handleDropdownVisibleChange = (visible) => {
    // Check if the color picker is open, and prevent closing the dropdown
    if (isColorPickerOpen) {
      setIsColorPickerOpen(false);
      return;
    }

    setIsDropdownVisible(visible);
  };

  const onFinish = async () => {
    const values = await form.validateFields();

    try {
      const shipmentTagsArray = addShipmentTags(
        values,
        rows,
        companyTags,
        shipment
      );
      // adding tags to the shipment
      await addShipmentData(shipmentTagsArray);

      // eslint-disable-next-line
    } catch (e: any) {
      displayErrors(e, { title: 'Error on adding tags to shipment' });
    }

    const createTagArray = createShipmentTags(values, rows);

    for (let i = 0; i < createTagArray.length; i++) {
      // creating new tags and adding it to shipment
      try {
        const { h, s, v } = createTagArray[i].colorCode.metaColor.originalInput;
        const hexColor = hsvToHex(h, s, v);

        const outputObject = {
          CompanyMetadataDto: {
            metadataKey: createTagArray[i].title,
            colorCode: hexColor,
          },
          ShipmentMetadataValuesDto: [
            {
              metadataValue: createTagArray[i].value,
              shipmentId: shipment.id,
            },
          ],
        };
        await createShipmentTag({ shipmentTag: outputObject });
        // eslint-disable-next-line
      } catch (e: any) {
        displayErrors(e, { title: 'Error on adding tags to company' });
      }
    }
    form.resetFields();
    handleCloseButtonClick();
  };

  const onSelectItemClick = (event) => {
    event.stopPropagation();
  };

  const defaultColor = (rowIndex: number): string | Color | undefined => {
    const title = form.getFieldsValue()[`title-${rowIndex}`];
    const color = companyTags?.find(
      (tag) => tag.metadataKey === title
    )?.colorCode;

    form.setFieldsValue({ [`colorCode-${rowIndex}`]: color });

    return color;
  };

  const allowManageTags = !isViewer(shipment.currentCollaborator.accessLevel);
  const isDanubeTeam = isDanube(shipment.createdBy.company.id)
    ? isDanubeAccountTeam(user?.id || '')
    : true;

  const showEditTagModal = (tag) => {
    setIsModalOpen(true);
    setSelectedTag(tag);
  };

  const hideEditTagModal = () => {
    setIsModalOpen(false);
    setSelectedTag(null);
    form.setFieldsValue(null);
  };

  const submitEditTagValue = async () => {
    try {
      const editTagFormValue = await editTagForm.validateFields();
      await updateTagsById(editTagFormValue).unwrap();
      hideEditTagModal();
      message.success('Tag updated successfully');
    } catch (error: any) {
      displayErrors(error, { title: 'Tag Edit Error' });
    }
  };

  return (
    <>
      <Space size={[0, 8]} wrap>
        {shipmentTags?.map((tag, index) => {
          const tagElem = (
            <Tag
              color={tag.companyMetadata?.colorCode}
              style={{
                backgroundColor: `${tag.companyMetadata?.colorCode}20`,
                color: tag.companyMetadata?.colorCode,
                border: `solid 1px ${tag.companyMetadata?.colorCode}`,
              }}
              key={tag.id}
              onClose={() => handleDeleteTag(tag.id)}
              closable={allowManageTags && isDanubeTeam}
              closeIcon={
                <CloseOutlined
                  style={{
                    color: tag.companyMetadata?.colorCode,
                  }}
                />
              }
              {...(allowManageTags &&
                isDanubeTeam && {
                  onClick: () => showEditTagModal(tag),
                })}
            >
              <span
                onDoubleClick={(e) => {
                  if (index !== 0) {
                    setEditInputValue(tag.id);
                    e.preventDefault();
                  }
                }}
              >
                {tag.companyMetadata?.metadataKey} : {tag.metadataValue}
              </span>
            </Tag>
          );
          return tagElem;
        })}
        {allowManageTags && (
          <Dropdown
            trigger={['click']}
            overlayClassName="dropdown-container"
            placement="bottomLeft"
            arrow={false}
            open={isDropdownVisible}
            onOpenChange={handleDropdownVisibleChange}
            dropdownRender={() => (
              <Form
                className="tags-container"
                form={form}
                name="tags_form"
                autoComplete="off"
                data-testid="tag-tooltip"
              >
                <Row className="dropDown-header">
                  <Col span={16}>
                    <span className="field-wrapper__text">Tags</span>
                  </Col>
                  <Col span={2} offset={6}>
                    <CloseOutlined onClick={handleCloseButtonClick} />
                  </Col>
                </Row>

                <Row className="form-header" align="middle" gutter={[12, 12]}>
                  <Col span={6}>
                    <span className="field-wrapper__text">Title</span>
                  </Col>
                  <Col span={6}>
                    <span className="field-wrapper__text">Values</span>
                  </Col>
                  <Col span={6}>
                    <span className="field-wrapper__text">Color</span>
                  </Col>
                  <Col span={6}>
                    <span className="field-wrapper__text">Actions</span>
                  </Col>
                </Row>

                {rows?.map((row, rowIndex) => (
                  <>
                    {row.type === 'addTag' ? (
                      <Row key={rowIndex} gutter={[12, 12]}>
                        <Col span={6}>
                          <Form.Item
                            name={`title-${rowIndex}`}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: 'Title is required',
                              },
                            ]}
                          >
                            <Select
                              aria-required
                              placeholder="Select Tag"
                              onChange={onSelectShipmenTag}
                              onClick={(event) => onSelectItemClick(event)}
                            >
                              {isUserAdmin && (
                                <Select.Option disabled>
                                  <Button
                                    onClick={() =>
                                      handleAddNewCreateRow(rowIndex)
                                    }
                                    type="link"
                                  >
                                    + Create new title
                                  </Button>
                                </Select.Option>
                              )}

                              {companyTags?.map((tag) => (
                                <Select.Option
                                  key={tag.metadataKey}
                                  value={tag.metadataKey}
                                >
                                  {tag.metadataKey}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={`values-${rowIndex}`}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              onClick={(event) => onSelectItemClick(event)}
                              aria-required
                              mode="tags" // Enable multiple values input as tags
                              placeholder="Add Values"
                            >
                              {shipmentTagValues?.map((tag) => (
                                <Select.Option key={tag} value={tag}>
                                  {tag}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={`colorCode-${rowIndex}`}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: false,
                              },
                            ]}
                          >
                            <ColorPicker
                              disabled
                              defaultValue={defaultColor(rowIndex)}
                            ></ColorPicker>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Button
                            disabled={rows.length < 2}
                            onClick={() => handleRemoveRow(rowIndex)}
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      <Row key={rowIndex} gutter={[12, 12]}>
                        <Col span={6}>
                          <Form.Item
                            name={`title-${rowIndex}`}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: 'Title is required',
                              },
                            ]}
                          >
                            <Input placeholder="Tag title"></Input>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={`values-${rowIndex}`}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              onClick={(event) => onSelectItemClick(event)}
                              aria-required
                              mode="tags"
                              placeholder="Add Values"
                            >
                              {shipmentTagValues?.map((tag) => (
                                <Select.Option key={tag} value={tag}>
                                  {tag}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={`colorCode-${rowIndex}`}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <ColorPicker
                              style={{ zIndex: 9999 }}
                              defaultValue="#0062ff"
                              format="hex"
                              onChange={() => setIsColorPickerOpen(true)}
                            ></ColorPicker>
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Button onClick={() => handleRemoveRow(rowIndex)}>
                            Remove
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={handleAddNewRow}>
                    Add another
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Row justify="end">
                    <Button
                      type="primary"
                      onClick={onFinish}
                      loading={
                        createShipmentTagLoading || addShipmentDataLoading
                      }
                    >
                      Save
                    </Button>
                  </Row>
                </Form.Item>
              </Form>
            )}
          >
            <Tag
              onClick={() => setIsDropdownVisible(true)}
              style={tagPlusStyle}
              icon={<PlusOutlined />}
            >
              New Tag
            </Tag>
          </Dropdown>
        )}
      </Space>
      {/* TODO: Urgent feat - Refactor required */}
      {selectedTag && (
        <Modal
          title="Edit Tag Value"
          open={isModalOpen}
          onCancel={hideEditTagModal}
          footer={[
            <Button
              key="cancel"
              onClick={hideEditTagModal}
              loading={isUpdateTagLoading}
            >
              Cancel
            </Button>,
            <Button
              type="primary"
              key="submit"
              onClick={submitEditTagValue}
              loading={isUpdateTagLoading}
            >
              Submit
            </Button>,
          ]}
        >
          <Form
            className="mt-2"
            form={editTagForm}
            layout="vertical"
            initialValues={{
              metadataValue: selectedTag.metadataValue,
              shipmentMetadataValueId: selectedTag.id,
            }}
          >
            <Form.Item hidden name="shipmentMetadataValueId">
              <Input placeholder="Enter tag value" />
            </Form.Item>
            <Form.Item
              label="Tag Value"
              name="metadataValue"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Enter tag value" required />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default ShipmentTags;
