import { DownOutlined } from '@ant-design/icons';
import {
  Dropdown,
  Button,
  MenuProps,
  Checkbox,
  Space,
  notification,
  Empty,
  Badge,
} from 'antd';
import {
  useAssignContainerToBookingMutation,
  useUnAssignContainerToBookingMutation,
} from 'api/bookings';
import { useGetBookingContainersListQuery } from 'api/container';
import React, { useEffect } from 'react';
import { IBookingContainerEntity } from 'types/entities/container.entity';
import { IBookinContainerDropDown } from 'types/feature/bookings.types';

import { displayErrors } from 'utils/error-notification';

const BookingContainersDropdown = ({ bookingId }: IBookinContainerDropDown) => {
  const { data: containers } = useGetBookingContainersListQuery(bookingId, {
    refetchOnMountOrArgChange: true,
  });

  const [
    assignBookingContainer,
    { isLoading: isAssignLoading, isSuccess: isAssignSucess },
  ] = useAssignContainerToBookingMutation();
  const [
    unassingBookingContainer,
    { isLoading: isUnAssignLoading, isSuccess: isUnAssignSucess },
  ] = useUnAssignContainerToBookingMutation();

  const onAssignContainerClick = async (
    bookingId: string,
    containerId: string
  ) => {
    try {
      await assignBookingContainer({ bookingId, containerId }).unwrap();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Failed to unassign the container' });
    }
  };

  const onUnassignContainerClick = async (
    bookingId: string,
    containerId: string
  ) => {
    try {
      await unassingBookingContainer({ bookingId, containerId }).unwrap();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      displayErrors(error, { title: 'Failed to unassign the container' });
    }
  };

  const menu: MenuProps = {
    items:
      containers && containers.length > 0
        ? containers.map((container: IBookingContainerEntity) => ({
            key: container.id,
            label: (
              <Space onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  defaultChecked={container.isBooked}
                  disabled={isAssignLoading || isUnAssignLoading}
                  onChange={(e) => {
                    e.target.checked
                      ? onAssignContainerClick(bookingId, container.id)
                      : onUnassignContainerClick(bookingId, container.id);
                  }}
                />
                {container.number.toString()}
              </Space>
            ),
            onClick: (e) => {
              e.domEvent.stopPropagation();
              onAssignContainerClick(bookingId, container.id);
            },
          }))
        : [
            {
              key: 'empty',
              label: (
                <Empty
                  className="empty-containers"
                  description="No containers"
                />
              ),
            },
          ],
  };
  useEffect(() => {
    if (isAssignSucess) {
      notification.success({ message: 'Container Assigned' });
    }
  }, [isAssignSucess]);

  useEffect(() => {
    if (isUnAssignSucess) {
      notification.success({ message: 'Container Unassigned' });
    }
  }, [isUnAssignSucess]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown trigger={['click']} menu={menu}>
        <Button icon={<DownOutlined />}>
          <Space>
            Assign Container
            {containers &&
              containers?.filter((container) => container.isBooked).length >
                0 && (
                <Badge
                  color="gray"
                  count={
                    containers?.filter((container) => container.isBooked).length
                  }
                />
              )}
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
};

export default BookingContainersDropdown;
