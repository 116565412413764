import {
  IBookingCount,
  IBookingEntity,
  IBookingPurchaseItemEntity,
} from 'types/entities/booking.entity';
import { IUpdateContainerBookingRequestBody } from 'types/entities/container.entity';
import {
  AssignBookingsReqBody,
  CreateBookingsForReqBody,
  CreateBookingsReqBody,
  CreateBookingsResBody,
  UnassignBookingsReqBody,
  UpdateBookingsReqBody,
} from 'types/feature/bookings.types';
import { BookingFilters } from 'types/feature/pagination.types';

import { api } from './base-api';
import { ApiResponse, IEntityList } from './common/response.type';

export const bookingApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBookings: build.query<
      ApiResponse<IEntityList<IBookingEntity>>,
      BookingFilters
    >({
      query: (payload) => ({
        url: '/booking',
        params: payload,
      }),
      providesTags: (
        result: ApiResponse<IEntityList<IBookingEntity>> = {
          data: { items: [] },
          statusCode: 200,
          message: '',
        },
        error,
        arg
      ) => [
        'Bookings',
        ...(result
          ? result.data.items.map((item) => ({
              type: 'Bookings' as const,
              id: item.id,
            }))
          : []),
      ],
    }),
    getCratedBookings: build.query<
      ApiResponse<IEntityList<IBookingEntity>>,
      { bookingIds: string[] }
    >({
      query: (payload) => ({
        url: '/booking/created',
        params: payload,
      }),
      providesTags: (
        result: ApiResponse<IEntityList<IBookingEntity>> = {
          data: { items: [] },
          statusCode: 200,
          message: '',
        },
        error,
        arg
      ) => [
        'Bookings',
        ...(result
          ? result.data.items.map((item) => ({
              type: 'Bookings' as const,
              id: item.id,
            }))
          : []),
      ],
    }),
    getBookingById: build.query<IBookingEntity, string>({
      query: (id) => ({
        url: `/booking/${id}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'Bookings', id: arg }],
      transformResponse: (response: ApiResponse<IBookingEntity>) =>
        response.data,
    }),
    getBookingsCount: build.query<ApiResponse<IBookingCount>, void>({
      query: () => ({
        url: '/booking/count',
      }),
      providesTags: ['BookingsCount'],
    }),
    createBookings: build.mutation<
      ApiResponse<CreateBookingsResBody>,
      CreateBookingsReqBody
    >({
      query: (payload: CreateBookingsReqBody) => ({
        url: '/booking',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['PurchaseOrders', 'Bookings'],
    }),
    createBookingForShipment: build.mutation<
      ApiResponse<CreateBookingsResBody>,
      CreateBookingsForReqBody
    >({
      query: ({ body, shipmentId }) => ({
        url: `/booking/shipment/${shipmentId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PurchaseOrders', 'Bookings'],
    }),
    updateBookings: build.mutation<
      ApiResponse<CreateBookingsResBody>,
      UpdateBookingsReqBody
    >({
      query: (payload: UpdateBookingsReqBody) => ({
        url: '/booking',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['PurchaseOrders', 'Bookings'],
    }),
    assignContainerToBooking: build.mutation<
      ApiResponse<CreateBookingsResBody>,
      IUpdateContainerBookingRequestBody
    >({
      query: ({ bookingId, containerId }) => ({
        url: `/booking-containers`,
        method: 'POST',
        body: { bookingId, containerId },
      }),
      invalidatesTags: ['Bookings'],
    }),
    unAssignContainerToBooking: build.mutation<
      ApiResponse<CreateBookingsResBody>,
      IUpdateContainerBookingRequestBody
    >({
      query: ({ bookingId, containerId }) => ({
        url: `/booking-containers`,
        method: 'DELETE',
        body: { bookingId, containerId },
      }),
      invalidatesTags: ['Bookings'],
    }),
    deleteBookingById: build.mutation<ApiResponse<string>, string>({
      query: (id) => ({
        url: `/booking/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (res, err, args) => [
        'Bookings',
        'PurchaseOrders',
        { type: 'Bookings', id: args },
      ],
    }),
    updateBookingById: build.mutation<
      ApiResponse<string>,
      Partial<IBookingEntity>
    >({
      query: (payload) => ({
        url: `/booking/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (res, err, args) => [{ type: 'Bookings', id: args.id }],
    }),
    deleteBookingItems: build.mutation<
      ApiResponse<string>,
      { ids: string[]; bookingId: string }
    >({
      query: (payload) => ({
        url: `/booking/items/${payload.bookingId}`,
        method: 'DELETE',
        body: payload,
      }),
      invalidatesTags: ['Bookings', 'PurchaseOrders'],
    }),
    updateBookingItems: build.mutation<
      ApiResponse<string>,
      { purchaseOrderItems: IBookingPurchaseItemEntity[] }
    >({
      query: (payload) => ({
        url: `/booking/items`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['Bookings'],
    }),
    assignBookingsToShipment: build.mutation<
      ApiResponse<string>,
      AssignBookingsReqBody
    >({
      query: ({
        shipmentId,
        isCreateMode,
        ...payload
      }: AssignBookingsReqBody) => ({
        url: `/shipment/assign-bookings/${shipmentId}`,
        method: 'PATCH',
        body: payload,
        params: { isCreateMode },
      }),
      invalidatesTags: ['Bookings', 'BookingsCount'],
    }),
    unassignBookingsToShipment: build.mutation<
      ApiResponse<string>,
      UnassignBookingsReqBody
    >({
      query: ({ shipmentId, ...payload }: UnassignBookingsReqBody) => ({
        url: `/shipment/unassign-bookings/${shipmentId}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['Bookings', 'ShipmentsById', 'BookingsCount'],
    }),
    downloadBookingsSheet: build.mutation<
      { data: Iterable<number> },
      { bookingIds: string[] }
    >({
      query: ({ bookingIds }) => ({
        url: `/booking/download`,
        method: 'POST',
        body: { bookingIds },
        responseType: 'arraybuffer',
        contentType: 'blob',
      }),
    }),
  }),
});

export const {
  useCreateBookingsMutation,
  useLazyGetBookingsQuery,
  useLazyGetCratedBookingsQuery,
  useGetBookingByIdQuery,
  useGetBookingsQuery,
  useGetCratedBookingsQuery,
  useGetBookingsCountQuery,
  useDeleteBookingByIdMutation,
  useUpdateBookingByIdMutation,
  useDeleteBookingItemsMutation,
  useAssignBookingsToShipmentMutation,
  useUnassignBookingsToShipmentMutation,
  useUpdateBookingsMutation,
  useAssignContainerToBookingMutation,
  useUnAssignContainerToBookingMutation,
  useUpdateBookingItemsMutation,
  useCreateBookingForShipmentMutation,
  useDownloadBookingsSheetMutation,
} = bookingApi;
