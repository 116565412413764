import dayjs from 'dayjs';
import { CurrencyEnum } from 'types/entities/fee.entity';
import { IncotermsEnum } from 'types/entities/product.entity';
import {
  IPOParsedDocumentEntity,
  IPOParsedDocumentLineItemEntity,
  IPurchaseOrderEntity,
  IPurchaseOrderItemEntity,
  MeasurementUnitEnum,
  PurchaseOrderStatusEnum,
} from 'types/entities/purchase-order.entity';
import { ModalityEnum } from 'types/entities/shipment.entity';

const getIncoterm = (items: IPOParsedDocumentLineItemEntity[]) => {
  return items?.length
    ? Object.values(IncotermsEnum).find(
        (item) =>
          (items[0]['line_item/incoterm'] as string)?.toLowerCase() === item
      )
    : undefined;
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const mapOrderItems = (
  jsonItem: IPOParsedDocumentLineItemEntity,
  currency: CurrencyEnum,
  defaultCargoReadyDate: string | undefined
) => {
  const item: IPurchaseOrderItemEntity = {
    description: jsonItem.description ?? jsonItem.productCode,
    reference: jsonItem.productCode,
    requestedQuantity: jsonItem.quantity,
    unitPrice: jsonItem.unitPrice,
    totalWeight: jsonItem.weight,
    totalVolume: jsonItem.volume,
    boxes: jsonItem.boxes,
    currencyCode: currency,
    cargoReadyDate:
      defaultCargoReadyDate ??
      dayjs(jsonItem.itemDeliveryDate ?? dayjs()).format('YYYY-MM-DDTHH:mm:ss'),
    totalAmount: jsonItem.unitPrice * jsonItem.quantity,
    taxRate: 0,
    shipments: [],
    bookedQuantity: null,
    unitOfMeasurement: MeasurementUnitEnum.QUANTITY,
    volumePerUnit: null,
    weightPerUnit: null,
  };

  return item;
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const mapJSONToPurchaseOrder = (response: IPOParsedDocumentEntity) => {
  const currency = CurrencyEnum[response.currency] ?? CurrencyEnum.USD;
  const order: Partial<IPurchaseOrderEntity> = {
    status: response.status ?? PurchaseOrderStatusEnum.IN_PRODUCTION,
    orderIssueDate: dayjs(response.purchaseOrderDate ?? dayjs()).format(
      'YYYY-MM-DDTHH:mm:ss'
    ),

    eta: '',
    etd: '',
    destination: {
      country: response?.destination,
      code: '',
      city: response?.destination,
      latitude: 0,
      longitude: 0,
      serviceMode: null,
    },
    origin: {
      country: response?.origin,
      code: '',
      city: response?.origin,
      latitude: 0,
      longitude: 0,
      serviceMode: null,
    },
    reference: response.purchaseOrderId,
    attachments: [],
    supplierId: '',
    supplier: undefined,
    requested: 0,
    booked: 0,
    modality: response.modality ?? ModalityEnum.SEA,
    incoterms: getIncoterm(response.lineItems),
    purchaseOrderItems:
      response.lineItems?.map((item) =>
        mapOrderItems(
          item,
          currency,
          response.cargoReadyDate
            ? dayjs(response.cargoReadyDate).format('YYYY-MM-DDTHH:mm:ss')
            : undefined
        )
      ) ?? [],
  };

  return order;
};
